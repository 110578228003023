import {Action} from '@ngrx/store';

export const SHOP_DATA_CONSTANTS = {
  SAVE_SHOP_DATA: '[Shop Detail] Save',
  RESET_SHOP_DETAIL: '[Shop Detail] Reset',
};


class SaveShopData implements Action {
  readonly type = SHOP_DATA_CONSTANTS.SAVE_SHOP_DATA;
  constructor(public payload: any) {
  }
}

class ResetShopData implements Action {
  readonly type = SHOP_DATA_CONSTANTS.RESET_SHOP_DETAIL;
}

export const ShopDataActions = {
  saveDetail: SaveShopData,
  restDetails: ResetShopData
};

export const ShopDataSelectors = {
  detail: (state: any) => state._shop,
};

export type ShopDataActionTypes = SaveShopData;
