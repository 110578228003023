<!-- <div> -->
<div class="manageFunds" id="Transactions">
    <div class="modal-header">
        <h5 class="modal-title">Brands</h5>
        <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
    </div>

    <app-loader *ngIf="loading.api"></app-loader>

    <div class="modal-body" *ngIf="brands?.length > 0 && !loading.api">
        <div id="accordion" class="transactionBody outletCollapse">
            <table class="table">
                <thead>
                    <tr>
                        <th>Name</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let brand of brands; let index=index;">
                        <td>
                            <div class="ocHeader">
                                <a [attr.data-toggle]="'collapse'" [attr.data-target]="'#brand'+index">{{ brand.name }} </a>
                            </div>
                            <ul class="collapse oc-ul" [id]="'brand'+index" data-parent="#accordion">
                                <li *ngFor="let outlet of brand.outlets">
                                    {{outlet.name}}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="" *ngIf="brands?.length === 0">
        <p class="m-48 text-center text mt-3">No Data found</p>
    </div>

</div>