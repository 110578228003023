import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormValidator } from '../../validators/index';
import { HttpRequestService } from '../../core/http-request.service';
import { ToastrService } from 'ngx-toastr';
import { API_URL } from '../../core/api-end-points';
import { Utils } from 'src/app/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private httpRequestService: HttpRequestService,
    private toastr: ToastrService
  ) { }

  formGroup: FormGroup = this.formBuilder.group({
    email: new FormControl('', [Validators.compose([Validators.required, FormValidator.validEmail])]),
    password: new FormControl('', [Validators.compose(
      [Validators.required])])
  });

  forgotFormGroup: FormGroup = this.formBuilder.group({
    email: new FormControl('', [Validators.compose([Validators.required, FormValidator.validEmail])])
  });

  loading = { form: false };
  show: boolean = false;

  ngOnInit(): void {
  }

  forgotPassword() {
    var element = document.getElementById("loginSection");
    element.classList.add("forgotPassword");
  }

  signIn() {
    var element = document.getElementById("loginSection");
    element.classList.remove("forgotPassword");
  }

  login() {
    if (this.formGroup.valid) {
      this.loading.form = true;
      const param = this.formGroup.value;
      const url = API_URL._POST_LOGIN;
      this.httpRequestService.postData(url, param).subscribe(
        (resp: any) => {
          localStorage.setItem('userDetails', JSON.stringify(resp.data));
          this.loading.form = false;
          this.toastr.success(`Login Successfully!`, `Success`, { timeOut: 1500 });
          this.formGroup.reset();
          this.router.navigate(['/admin']);
        }, (error => {
          this.loading.form = false;
          let errorMessage = Utils.printErrors(error);
          this.toastr.error(errorMessage, `Error`, { timeOut: 1500 });
        })
      )
    } else {
      Object.keys(this.formGroup.controls).forEach(key => {
        this.formGroup.get(key).markAsTouched({ onlySelf: true });
      });
    }
  }

  onForgotPasswordSubmit() {
    if (this.forgotFormGroup.valid) {
      this.loading.form = true;
      const param = this.forgotFormGroup.value;
      const url = API_URL._POST_FORGOT_PASSWORD;
      this.httpRequestService.postData(url, param).subscribe(
        (resp: any) => {
          this.toastr.success('Email has been sent', `Success`, { timeOut: 1500 });
          this.forgotFormGroup.reset();
          this.loading.form = false;
        }, (error => {
          this.loading.form = false;
          let errorMessage = Utils.printErrors(error);
          this.toastr.error(errorMessage, `Error`, { timeOut: 1500 });
        })
      )
    } else {
      Object.keys(this.forgotFormGroup.controls).forEach(key => {
        this.forgotFormGroup.get(key).markAsTouched({ onlySelf: true });
      });
    }
  }

  showHidePassword() {
    this.show = !this.show;
  }
}
