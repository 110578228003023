import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Linker } from 'src/app/core/linker.functions';
import { BrandDataSelectors, OutletDataSelectors } from 'src/app/redux/actions';
import { IBreadCrumb } from './breadcrumb.interface';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: IBreadCrumb[];
  brandId;
  outletId;
  brandName;
  subscription: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
  ) {

    this.store.pipe(select(BrandDataSelectors.detail)).subscribe((data) => {
      if (Object.keys(data).length) {
        this.brandId = data.id;
        this.brandName = data.name;
      }
    });

    this.store.pipe(select(OutletDataSelectors.detail)).subscribe((data) => {
      if (Object.keys(data).length) {
        this.outletId = data.id;
        // this.brandName = data.name;
      }
    });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
      this.breadcrumbs = this.breadcrumbs.map((bcrum) => {
        bcrum.url = bcrum.url.replace(':brandId', this.brandId);
        bcrum.label = bcrum.label.replace(':brandId', this.brandId);
        bcrum.url = bcrum.url.replace(':outletId', this.outletId);
        return bcrum;
      });
    });
  }

  ngOnInit(): void {
   
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    //If no routeConfig is avalailable we are on the root path
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = route.snapshot.params[paramName];
    }

    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: IBreadCrumb = {
      label: label,
      url: nextUrl,
    };
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  getItemLink(page) {
    const link = Linker.breadcrumbLink(page)
    return link;
  }

}
