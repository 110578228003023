<div class="modalDetails">
    <div class="modal-header">
      <h5 class="modal-title">{{ params?.name }}</h5>
      <button type="button" class="close" (click)="close()">&times;</button>
    </div>
  
    <div class="modal-body">
      <form class="modalDetails-in">
        <div>
          <p class="sm">Name:</p>
          <p class="text">{{ params?.name }}</p>
        </div>
        <div>
          <p class="sm">Email:</p>
          <p class="text">{{ params?.email }}</p>
        </div>
      
        <div>
          <p class="sm">Contact Number:</p>
          <p class="text">{{ params?.mobile }}</p>
        </div>

        <div>
            <p class="sm">Message:</p>
            <p class="text">{{ params?.message }}</p>
          </div>
    
      </form>
    </div>
  </div>