import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from '../../modals/modal.service';
import {AuthService} from '../../services';
import {Router} from '@angular/router';
import {HttpRequestService, LoggerService} from '../../core';
import {select, Store} from '@ngrx/store';
import {Subscriber} from 'rxjs';
import {UserDataSelectors} from '../../redux/actions';
import {logger} from 'codelyzer/util/logger';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {

  constructor(
    private popupService: ModalService,
    private authService: AuthService,
    private router: Router,
    private loggerService: LoggerService,
    private httpService: HttpRequestService,
    private store: Store,
    private changeDetector: ChangeDetectorRef
  ) {
    this.subscriber.add(this.store.pipe(select(UserDataSelectors.detail)).subscribe(userDetail => {
      // this.loggerService.log(userDetail);
      if (userDetail && Object.keys(userDetail).length) {
        this.currentUser = userDetail;
        return this.currentUser;
      } else {
        this.currentUser = null;
        return false;
      }
    }));
  }

  private subscriber: Subscriber<any> = new Subscriber<any>();

  isLoggedIn = false;
  currentUser: any = {};
  currentRegion: any = {};
  cart: any = {orderType: '', shopId: '', items: [], finalOrder: {}};


  ngOnInit(): void {
  }

  openLocationPicker() {
    this.popupService.openLocationPickerPopup();
  }


  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
    this.changeDetector.detach();
  }

}
