import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppliedFiltersActions } from '../../redux/actions/applied-filters.action';
import { Utils } from 'src/app/core';
import { DpModalService } from 'src/app/services';
import { DpModalParams } from 'src/app/_modal/modal.params';

@Component({
  selector: 'app-listing-filters',
  templateUrl: './listing-filters.component.html',
  styleUrls: ['./listing-filters.component.scss']
})
export class ListingFiltersComponent implements OnInit {

  filterName: string = '';
  allFilters: any[] = [];

  constructor(
    private store: Store<any>,
    private overlayParams: DpModalParams,
    private modalService: DpModalService) { }

  ngOnInit() {
    if(this.overlayParams && this.overlayParams.filters) {
      this.filterName = this.overlayParams.tableName;
      this.allFilters = this.overlayParams.filters.map(_filter => {
        if(!_filter.value) {
          _filter.value = ''
        }
        return _filter;
      });
    } 

    // refresh selectpickers
    Utils.refreshSelectpicker();
  }

  /**
   * Update filter
   * @param _filter
   */
  updateFilter(event, _filter) {
    return _filter.value = event.target.value;
  }

  /**
   * Update status filter
   * @param _filter
   */
  updateStatus(_status, _filter) {
    return _filter.value = _status;
  }

  /**
   * Update Date picker
   * @param value
   * @param _filter
   */
  updateDatePicker(value, _filter) {
    // return _filter.value = Utils.getUTCDate(value, _filter.atr);
    return _filter.value = value;
  }

  /**
   * Filter Records by Filters
   */
  filterRecords() {
    // this.logger.log(this.allFilters);
    this.store.dispatch(new AppliedFiltersActions.saveTableListing({[this.filterName]: this.allFilters}));
    this.close();
  }

  /**
   * Reset Filters
   */
  resetFilters() {
    // reset all filters
    this.allFilters = this.allFilters.map(_filter => { _filter.value = ''; return _filter; });
    this.store.dispatch(new AppliedFiltersActions.saveTableListing({[this.filterName]: []}));
  }


  getValue(date){
  }

  /**
   * Close popup
   */
  close() {
    this.modalService.close('listingFilters');
  }
}
