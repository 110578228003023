export class ListingTableSettings {
    static?:              boolean;
    perpage:              number;
    viewLink?:            string;    // link to view screen
    hasSelection?:        boolean;   // if can select from checkbox
    hasDragDrop?:         boolean;   // if has drag & drop
    hasActions?:          boolean;   // if has drag & drop
    canAdd?:              boolean;   // if can add new
    newRecordButton?:     boolean;   // if can add new
    addButtonText?:       string;    // if can add new
    canEdit?:             boolean;   // if can edit record
    canView?:             boolean;   // if can view record
    canRefund?:           boolean;   //if can refund record
    canExport?:           boolean;   // if can export record
    canDelete?:           boolean;   // if can delete record
    expandRows?:          boolean;   // show expand icon
    filterRows?:          boolean;   // show filter rows
    columnsBtn?:          boolean;   // show filter rows
    refreshRows?:         boolean;   // execute refresh rows
    showCharts?:          boolean;   // show row charts
    switchName?:          string;    // switch field name
    collapseRows?:        boolean;   // show collapsed icon
    chartsPrefix?:        string;    // prefix for charts
    canSearch?:           boolean;
    searchPlaceholder?:   string;
    filterTabs?:    ListingTableTab[];        // if has tabs for filter (left)
    columns?:       ListingTableColumn[];     // all table columns list
    filters?:       ListingTableFilters[];    // filters on the table
    actionList?:    ListingTableAction[];     // actions on right side
    actionDrop?:    ListingTableAction[];     // actions button with dropdown
    noRecord?: boolean;
  }
  
  export class ListingTableColumn {
    id:       number;
    name:     string;
    key?:      string;
    sort:     boolean;
    label:    string;
    display:  string;
    info?:    string;
    hasLink?: boolean;
    trim?:    number;
    hasAction?: boolean;
  }
  
  export class ListingTableTab {
    name:     string;
    key?:     string;
    filter:   string;
    filterBy?: string;
  }
  
  export class ListingTableAction {
    id:     number;
    name:   string;
    type?:  string;
    icon?:  string;
    visible?: any;
  }
  
  export class ListingTableFilters {
    name:   string;
    label:  string;
    type:   string;
    atr?: DATE_ATR;
    option?: any;
  }
  
  export enum DATE_ATR {
    START,
    END,
    EQUAL
  }
  