import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { API_URL, HttpRequestService } from 'src/app/core';
import { SearchService } from 'src/app/shared/search.service';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent implements OnInit {

  constructor(
    private httpRequestService: HttpRequestService,
    private popupService: BsModalService,
    private toastrService: ToastrService,
    private searchService: SearchService
  ) { }

  params;
  id;
  type;

  ngOnInit(): void {
    this.id = this.params._id ? this.params._id : this.params.value._id;
    this.type = this.params.type;
  }

  delete(){
    const url = API_URL._POST_DELETE_RECORD;
    const payload = {
      id: this.id,
      type: this.type
    }
    this.httpRequestService.postData(url ,payload).subscribe(
      (resp)=>{
        if(resp.statusCode === 200){
          // this.searchService.onDelete('true');
          this.params.execute(this.params.index); //delete execute
          this.toastrService.success('Deleted Successfully', 'Success');
          this.popupService.hide();
        }
      },(error)=>{
      }
    )
  }

  cancel(){
    this.popupService.hide();
  }

}
