import { Component, OnInit } from '@angular/core';
import { API_URL, HttpRequestService } from '@app/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-contact-detail-popup',
  templateUrl: './contact-detail-popup.component.html',
  styleUrls: ['./contact-detail-popup.component.scss']
})
export class ContactDetailPopupComponent implements OnInit {

  constructor(
    private httpRequestService: HttpRequestService,
    private popupService: BsModalService
  ) { }

  params;
  loading = {api:false};
  record;

  ngOnInit(): void {
  }

  close() {
    this.popupService.hide();
  }

}
