import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LeadRequestPopupComponent} from './lead-request-popup.component';
import { RouterModule, Routes } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '@app/directives';
import { HasAccessModule } from '@app/directives/has-access/has-access.module';

const routes: Routes = [
    { path: '', component: LeadRequestPopupComponent, 
}];

@NgModule({
  declarations: [
    LeadRequestPopupComponent,
  ],
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule,
    HasAccessModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    LeadRequestPopupComponent
  ],
  entryComponents: [
    LeadRequestPopupComponent
  ]
})
export class LeadRequestPopupModule {
}
