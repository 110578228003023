import {WHATSAPP_STEP_DATA_CONSTANTS, WhatsAppStepDataActionTypes} from '../actions';


const initialState = {whatsAppStep: 1};

export function WhatsAppStepDataReducer(state = initialState, action: WhatsAppStepDataActionTypes) {
  switch (action.type) {
    case WHATSAPP_STEP_DATA_CONSTANTS.SAVE_WHATSAPP_STEP_DATA:
      return {
        ...state,
        ...action.payload
      };

    case WHATSAPP_STEP_DATA_CONSTANTS.RESET_WHATSAPP_STEP_DETAIL:
      return initialState;

    default:
      return state;
  }
}
