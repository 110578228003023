export class DpModalParams {
    id?: number;
    name?: string;
    type?: string;
    data?: any;
    filters?: any;
    eventId?: number;
    menuId?: number;
    sectionId?: number;
    tableName?: string;
    scheduleData?: any;
    staffId?: any;
  }
  