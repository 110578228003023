<div class="data-tabs-wrapper">
    <div class="data-tabs-inner">
        <ul class="nav nav-tabs responsive-tabs" id="eventsTab" *ngIf="filterTabs.length">
            <li class="nav-item" *ngFor="let filteTab of filterTabs">
                <a class="nav-link" [ngClass]="{'active': isTabActive(filteTab.filter)}"
                    (click)="filterRecordByTab(filteTab)">
                    {{filteTab.name}} <span>{{tabFilterCount(filteTab)}}</span>
                </a>
            </li>
        </ul>
    </div>
</div>

<!-- show loader -->
<app-loader *ngIf="loading.api"></app-loader>

<ng-container *ngIf="!loading.api">
    <div class="tableSection">

        <table *ngIf="showTable">
            <thead>
                <tr>
                    <th width="30"></th>
                    <th width="50" *ngIf="settings.hasDragDrop">
                        <i class="info-ic" ngbTooltip="Change the positioning of plans by drag & drop in the table"
                            placement="top-left">
                            <img src="assets/images/info.png" width="18" height="18" alt="">
                        </i>
                    </th>
                    <th width="60" *ngIf="settings.showCharts"></th>

                    <th [ngClass]="{'sorting': singleColumn.sort}" *ngFor="let singleColumn of allColumns; let ci=index"
                        (click)="sortColumnData(singleColumn)">
                        <span>{{singleColumn.label}}</span>
                    </th>
                    <th *ngIf="settings.canEdit || settings.canView || settings.canDelete || actionList.length ">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody cdkDropList (cdkDropListDropped)="onRowReorder($event)"
                [cdkDropListDisabled]="!settings.hasDragDrop">
                <ng-container *ngIf="!loading.records">
                    <ng-container *ngFor="let singleRecord of filteredRecords | paginate: pageConfig; let i=index;">
                        <tr cdkDrag>
                            <td style="width:30px;" class="bg-light">{{getRowCount(i)}}.</td>
                            <td style="width:50px;" *ngIf="settings.hasDragDrop">
                                <i class="drag-list"><img src="assets/images/drag.png" width="12" height="18"
                                        alt=""></i>
                            </td>
                            <td style="width:60px;" class="table-drop-down" (click)="showRowChart(singleRecord.id)"
                                *ngIf="settings.showCharts">
                                <span class="angle-down"></span>
                            </td>

                            <ng-container *ngFor="let singleColumn of allColumns; let vi=index"
                                [ngSwitch]="singleColumn.display">
                                <td *ngSwitchCase="'text'" class="{{singleColumn.className}}">

                                    <a class="table-ticket-name" href="javascript://" (click)="viewRecord(singleRecord)"
                                        *ngIf="singleColumn.hasAction">
                                        <ng-container *ngTemplateOutlet="columnDetails"></ng-container>
                                    </a>

                                    <a class="table-ticket-name" [routerLink]="getViewLink(singleRecord, singleColumn)"
                                        [queryParams]="getViewLinkParams(singleRecord, singleColumn)"
                                        *ngIf="singleRecord[singleColumn.name]?.elink">
                                        <ng-container *ngTemplateOutlet="columnDetails"></ng-container>
                                    </a>
                                    <ng-container
                                        *ngIf="!singleRecord[singleColumn.name]?.elink && !singleColumn.hasAction">
                                        <ng-container *ngTemplateOutlet="columnDetails"></ng-container>
                                    </ng-container>
                                    <ng-template #columnDetails>
                                        <span *ngIf="!singleColumn.trim">{{ getColumnText(singleRecord,
                                            singleColumn) }}</span>

                                        <span [ngbTooltip]="fullTextTooltip" *ngIf="singleColumn.trim">
                                            {{ getColumnText(singleRecord, singleColumn) | truncate:
                                            singleColumn.trim }}
                                            <ng-template #fullTextTooltip>
                                                <div class="tooltip-custom">
                                                    <p>
                                                        {{ getColumnText(singleRecord, singleColumn) }}
                                                    </p>
                                                </div>
                                            </ng-template>
                                        </span>
                                        <p *ngIf="singleColumn.info" class="colm-desc">
                                            {{singleRecord[singleColumn.info]}}</p>
                                    </ng-template>
                                </td>

                                <td *ngSwitchCase="'number'" class="{{singleColumn.className}}">
                                    <span>{{singleRecord[singleColumn.name] | number : '0.0-2'}}</span>
                                </td>

                                <td *ngSwitchCase="'currency'" class="{{singleColumn.className}}">
                                    <span>{{currency}} {{singleRecord[singleColumn.name]}}</span>
                                </td>
                                <td *ngSwitchCase="'date'" class="{{singleColumn.className}}">
                                    {{singleRecord[singleColumn.name] | date : 'M/d/yyyy'}}
                                </td>

                                <td *ngSwitchCase="'dateTime'" class="{{singleColumn.className}}">
                                    {{singleRecord[singleColumn.name] | date : 'M/d/yyyy h:mm a'}}
                                </td>
                                <!-- <td><span class="text-grey">Afghanistan</span></td> -->

                                <td *ngSwitchCase="'text-grey'" class="{{singleColumn.className}}">
                                    <span class="text-grey"> {{ getColumnText(singleRecord, singleColumn) }}</span>
                                </td>

                                <td *ngSwitchCase="'info'" class="{{singleColumn.className}}">
                                    <a class="table-ticket-name" href="javascript:void(0)"
                                        (click)="openInfoPopup(singleRecord, singleColumn.name)">
                                        {{singleRecord[singleColumn.name]}}
                                    </a>
                                </td>

                                <td *ngSwitchCase="'colorfull-text'" class="{{singleColumn.className}}">
                                    <span [ngClass]="{'text-sky': singleRecord.status === 'New',
                                        'text-blue': singleRecord.status === 'Paid',
                                        'text-yellow': singleRecord.status === 'In Bargain',
                                        'text-red': singleRecord.status === 'Declined',
                                        'text-green': singleRecord.status === 'Confirmed'}"> {{
                                        getColumnText(singleRecord, singleColumn) }}</span>
                                </td>

                                <td *ngSwitchCase="'switch'" class="{{singleColumn.className}}">

                                    <div class="togglesw">
                                        <div class="toggleSwitch">
                                            <input type="checkbox" id="record-switch{{singleRecord.id}}" class="switch"
                                                [checked]="singleRecord[singleColumn.name]"
                                                (change)="enableDisable(singleRecord)">
                                            <span></span>

                                        </div>
                                        <label [ngClass]="{'': !singleRecord.isActive}" class="mb-0">{{ singleRecord.isActive ? 'Active' : 'Inactive' }}</label>
                                    </div>


                                </td>

                                <td *ngSwitchCase="'tag 1'" class="{{singleColumn.className}}">
                                    <span class="brandlogo">
                                        <label [ngClass]="{'delivery': singleRecord.type === 'Delivery' ,
                                            'scheduled': singleRecord.type === 'Scheduled',
                                            'pickup': singleRecord.type === 'Pick Up',
                                            'bulkorder': singleRecord.type === 'Bulk Order',
                                            'dinein': singleRecord.type === 'Dine In' }">{{ getColumnText(singleRecord,
                                            singleColumn) }}</label>
                                    </span>
                                </td>

                                <td *ngSwitchCase="'tag 2'" class="{{singleColumn.className}}">
                                    <span class="brandlogo">
                                        <label [ngClass]="{'orderPreparing': singleRecord.status === 'Preparing',
                                             'orderCompleted': singleRecord.status === 'Completed', 
                                             'orderCancelled': singleRecord.status === 'Cancelled'}">{{
                                            getColumnText(singleRecord,
                                            singleColumn) }}</label>
                                    </span>
                                </td>

                                <td *ngSwitchCase="'image'" class="{{singleColumn.className}}">
                                    <span class="brandlogo">
                                        <img class="img-fluid" [src]="singleRecord.location" />
                                    </span>
                                </td>

                                <td *ngSwitchCase="'video'" class="{{singleColumn.className}}">
                                    <span class="brandlogo">
                                        <video class="img-fluid" [src]="singleRecord.location"></video>
                                    </span>
                                </td>

                                <td *ngSwitchDefault class="{{singleColumn.className}}">-</td>
                            </ng-container>

                            <td class="rightRadius"
                                *ngIf="settings.canEdit || settings.canView || settings.canDelete || actionList.length ">
                                <div class="dropdown">

                                    <div class="tableLinks">
                                        <a *ngIf="settings.canEdit" href="javascript:void(0)"
                                            (click)="editRecord(singleRecord)">Edit</a>
                                        <a *ngIf="settings.canRefund" href="javascript:void(0)"
                                            (click)="refundRecord(singleRecord)">Refund</a>
                                        <a *ngIf="settings.canView" href="javascript:void(0)"
                                            (click)="viewRecord(singleRecord)">View detail</a>
                                        <a *ngIf="settings.canDelete" href="javascript:void(0)"
                                            (click)="deleteRecord(singleRecord)">Delete</a>
                                    </div>

                                    <div class="dropdown">

                                        <ng-container *ngIf="hideShowOptionList(singleRecord)">
                                            <button class="btn-dots" data-toggle="dropdown">
                                                <span></span>
                                            </button>
                                            <div class="dropdown-menu actionList">
                                                <ng-container *ngFor="let _action of actionList">

                                                    <!-- <li -->
                                                    <ng-container *ngIf="isActionListVisible(_action, singleRecord)">
                                                        <a class="" href="javascript:void(0);"
                                                            (click)="onActionItem(singleRecord, _action)">
                                                            {{_action.name}}
                                                        </a>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="!hideShowOptionList(singleRecord)">
                                            <a href="javascript:void(0)" class="icon-dots-wrapper menu-outer"><i
                                                    class="icon-menu"></i></a>
                                        </ng-container>

                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>


        <div class="" *ngIf="!loading.records && !filteredRecords?.length && showTable && (tableName == 'Plans' || tableName == 'Lead Requests' || 
        tableName == 'VideoBlog' || tableName == 'imageBlog' || tableName == 'NewsBlog' || tableName == 'Cuisines' || tableName == 'Currency' || tableName == 'Merchants' )">
            <!-- <h2 class="m-16">No subscription plans available</h2> -->
            <p class="m-48 text-center text mt-3">No Data found</p>
          </div>
          

        <!-- </div> -->

        <div class="data-empty" *ngIf="loading.records">
            <app-loader></app-loader>
        </div>

        <div class="pagination" *ngIf="!loading.records && filteredRecords?.length && pagination">

            <div class="table_length">
                <div class="pagination-wrap table-pagination">
                    <pagination-controls (pageChange)="setCurrentPage($event)">
                        <pagination-template #ipage="paginationApi"></pagination-template>
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>

</ng-container>