import {INSTA_STEP_DATA_CONSTANTS, InstaStepDataActionTypes} from '../actions';


const initialState = {instaStep: 1};

export function InstaStepDataReducer(state = initialState, action: InstaStepDataActionTypes) {
  switch (action.type) {
    case INSTA_STEP_DATA_CONSTANTS.SAVE_INSTA_STEP_DATA:
      return {
        ...state,
        ...action.payload
      };

    case INSTA_STEP_DATA_CONSTANTS.RESET_INSTA_STEP_DETAIL:
      return initialState;

    default:
      return state;
  }
}
