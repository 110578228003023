import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { API_URL, HttpRequestService } from 'src/app/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-merchant-invoice-popup',
  templateUrl: './merchant-invoice-popup.component.html',
  styleUrls: ['./merchant-invoice-popup.component.scss']
})
export class MerchantInvoicePopupComponent implements OnInit {

  constructor(
    private popupService: BsModalService,
    private httpRequestService: HttpRequestService
  ) { }

  loading = { api: false };
  params;
  allInvoices: any[] = [];
  currency;
  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  isPdfLoading: boolean = false;
  isCsvLoading: boolean = false;

  ngOnInit(): void {
    const settings = JSON.parse(localStorage.getItem('settings'));
    this.currency = settings.code;
    
    this.getInvoices();
  }

  getInvoices() {
    this.loading.api = true;
    const url = API_URL._GET_INVOICES;
    this.httpRequestService.getData(url, { vendorId: this.params._id }).subscribe(
      (resp: any) => {
        this.allInvoices = this.parsedData(resp.data.invoices);
        this.loading.api = false;
      }, (error) => {
        this.loading.api = false;
      }
    )
  }

  parsedData(allRecord) {
    return allRecord.map((record) => {
      record.status = record.status[0].toUpperCase() + record.status.substr(1).toLowerCase();
      // record._id = 
      return record;
    });
  }

  downloadCSV(id) {
    this.isCsvLoading = true;
    const url = API_URL._GET_INVOICES;
    const params = {
      isCsv: true,
      id: id,
      noLimit: true
    }
    this.httpRequestService.getData(url, params).subscribe(
      (resp: any) => {
        this.isCsvLoading = false;
        const csvContent =
          'data:text/csv;charset=utf-8,' + resp.data;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `Invoices by ${this.params?.name}.csv`);
        document.body.appendChild(link);

        link.click();
      }, (error) => {
        this.isCsvLoading = false;
      }
    )
    }
  
  downloadPDF(id) {
    this.isPdfLoading = true;
    const url = API_URL._GET_INVOICE_PDF;
    this.httpRequestService.getData(url, { id }).subscribe(
      (resp) => {
        this.isPdfLoading = false;
        const encodedUri = encodeURI(`${environment.API_END_POINT}${resp.data}`);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `Invoices by ${this.params?.name}.pdf`);
        document.body.appendChild(link);
        link.click();
      }, (error) => {
        this.isPdfLoading = false;
       }
    )
  }

  close() {
    this.popupService.hide();
  }

}
