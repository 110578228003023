import { Component, OnInit } from '@angular/core';
import { API_URL, HttpRequestService } from 'src/app/core';

@Component({
  selector: 'app-plan-change-popup',
  templateUrl: './plan-change-popup.component.html',
  styleUrls: ['./plan-change-popup.component.scss']
})
export class PlanChangePopupComponent implements OnInit {

  constructor(
    private httpRequestService: HttpRequestService
  ) { }

  allPlans: any[] =[];
  loading = {api: false};

  ngOnInit(): void {
    this.getPlans();
  }

  getPlans() {
    this.loading.api = true;
    const url = API_URL._GET_ALL_PLANS;
    // let params = this.applierFilters;
    this.httpRequestService.getData(url, {}).subscribe(
      (resp: any) => {
        this.allPlans = resp.data.plans;
        this.loading.api = false;
      }, (error) => {
        this.loading.api = false;
      }
    )
  }

}
