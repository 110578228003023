import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullLoaderComponent } from './full-loader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FullLoaderComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [FullLoaderComponent]
})
export class FullLoaderModule { }
