import {Action} from '@ngrx/store';

export const CART_DATA_CONSTANTS = {
  SAVE_CART_DATA: '[Cart Detail] Save',
  RESET_CART_DETAIL: '[Cart Detail] Reset',
};


class SaveCartData implements Action {
  readonly type = CART_DATA_CONSTANTS.SAVE_CART_DATA;
  constructor(public payload: any) {
  }
}

class ResetCartData implements Action {
  readonly type = CART_DATA_CONSTANTS.RESET_CART_DETAIL;
}

export const CartDataActions = {
  saveDetail: SaveCartData,
  restDetails: ResetCartData
};




export const CartDataSelectors = {
  detail: (state: any) => state._cart,
};

export type CartDataActionTypes = SaveCartData;
