import {Component, EventEmitter, NgZone, OnInit, Output} from '@angular/core';
import {HelperService} from '../../services/helper.service';

declare var google: any;

@Component({
  selector: 'app-current-location-picker-button',
  templateUrl: './current-location-picker-button.component.html',
  styleUrls: ['./current-location-picker-button.component.scss']
})
export class CurrentLocationPickerButtonComponent implements OnInit {

  constructor(
    private helperService: HelperService,
    private ngZone: NgZone,
  ) {
  }

  @Output() changeAddress: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
  }

  getPosition = () => {
    const latitude = null;
    const longitude = null;
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        resolve(position.coords);
      }, (err) => {
        reject(err);
      });
    });
  };

  async getCurrentLocation(): Promise<any> {
    let currentLocation = null;
    const coords: any = await this.getPosition();
    currentLocation = {
      lat: coords.latitude,
      lng: coords.longitude
    };
    return currentLocation;
  }

  getCurrentAddress() {

      let currentLocation = {lat: 0, lng: 0};
      this.getCurrentLocation().then((data: any) => {
        currentLocation = data;
        const geocoder = new google.maps.Geocoder;
        geocoder.geocode({location: data}, (results, status) => {
          if (status === 'OK') {
            const locationObj: any = {};
            if (results[0]) {
              const place = results[0];
              if (place.geometry && place.geometry.location) {
                const location = place.geometry.location;
                locationObj.latitude = location.lat();
                locationObj.longitude = location.lng();
                place.address_components.forEach((item: any) => {
                  locationObj.formatted_address = place.formatted_address;
                  if (item.types.indexOf('locality') > -1) {
                    locationObj.locality = item.long_name;
                  } else if (item.types.indexOf('administrative_area_level_1') > -1) {
                    locationObj.admin_area_l1 = item.short_name;
                  } else if (item.types.indexOf('street_number') > -1) {
                    locationObj.street_number = item.short_name;
                  } else if (item.types.indexOf('route') > -1) {
                    locationObj.route = item.long_name;
                  } else if (item.types.indexOf('country') > -1) {
                    locationObj.country = item.long_name;
                  } else if (item.types.indexOf('postal_code') > -1) {
                    locationObj.postal_code = item.short_name;
                  }
                });
              }
            }
            this.helperService.setRegion(locationObj);
            this.changeAddress.emit(locationObj);
          }
        });
      });
      return null;
  }
}
