import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent, SignupComponent, ForgotPasswordComponent, ResetPasswordComponent } from './authentication/index';
import { AuthGuard } from './guard/auth.guard';
import { LoginGuard } from './guard/login.guard';

const routes: Routes = [
  { path: '', canActivate: [LoginGuard], component: LoginComponent },
  { path: 'login', canActivate: [LoginGuard], component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'signup', component: SignupComponent },
  {
    path: 'admin', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/merchant-admin.module').then(m => m.MerchantAdminModule),
    data: { role: 'merchantAdmin', breadcrumb: 'Merchant Admin' }
  },
  { path: 'create-subscription-plan', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/create-new-subscription-plan/create-new-subscription-plan.module').then(m => m.CreateNewSubscriptionPlanModule) },
  { path: 'create-subscription-plan/:id', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/create-new-subscription-plan/create-new-subscription-plan.module').then(m => m.CreateNewSubscriptionPlanModule) },
  { path: 'create-currency', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/create-new-currency/create-new-currency.module').then(m => m.CreateNewCurrencyModule) },
  { path: 'create-currency/:id', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/create-new-currency/create-new-currency.module').then(m => m.CreateNewCurrencyModule) },

  { path: 'create-news', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/create-news-blog/create-news-blog.module').then(m => m.CreateNewsBlogModule) },
  { path: 'create-news/:id', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/create-news-blog/create-news-blog.module').then(m => m.CreateNewsBlogModule) },

  { path: 'create-image', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/create-image-blog/create-image-blog.module').then(m => m.CreateImageBlogModule) },
  { path: 'create-image/:id', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/create-image-blog/create-image-blog.module').then(m => m.CreateImageBlogModule) },

  { path: 'create-video', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/create-video-blog/create-video-blog.module').then(m => m.CreateVideoBlogModule) },
  { path: 'create-video/:id', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/create-video-blog/create-video-blog.module').then(m => m.CreateVideoBlogModule) },

  { path: 'create-manager', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/manager/create-manager/create-manager.module').then(m => m.CreateManagerModule) },
  { path: 'create-manager/:id', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/manager/create-manager/create-manager.module').then(m => m.CreateManagerModule) },

  { path: 'create-faq', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/create-faq/create-faq.module').then(m => m.CreateFaqModule) },
  { path: 'create-faq/:id', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/create-faq/create-faq.module').then(m => m.CreateFaqModule) },
  
  { path: 'create-role', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/create-role/create-role.module').then(m => m.CreateRoleModule) },
  { path: 'edit-role/:id', canActivate: [AuthGuard], loadChildren: () => import('./merchant-admin/create-role/create-role.module').then(m => m.CreateRoleModule) }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
