import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class DashboardActionService {

//   apiUrl  = environment.apiUrl;
  isLive  = environment.live;
  fakeApi = environment.fakeApi;
  eventIdKey: any = '_draftEventId';
  overlayData: any;
  overlayData2: any;
  private calRefresh       = new Subject<any>();
  private overlayType      = new Subject<any>();
  private tableRefresh     = new Subject<any>();
  private eventRefresh     = new Subject<any>();
  private overLayRefresh   = new Subject<any>();
  private refreshLabel     = new Subject<any>();
  private navBarSubject     = new Subject<any>();


  constructor() {
    this.overlayData  = this.defaultOverlayData();
    this.overlayData2 = this.defaultOverlayData();
  }

  // get draft event id
  setDraftEventId(eventId) {
    localStorage.setItem(this.eventIdKey, eventId);
  }

  // get draft event id
  getDraftEventId() {
    const foundEventID = localStorage.getItem(this.eventIdKey);
    return (foundEventID)? foundEventID : 0;
  }

  // remove draft event id
  removeDraftEventId() {
    localStorage.removeItem(this.eventIdKey);
  }

  // refresh table
  refreshTable(tableName) {
    this.tableRefresh.next(tableName);
  }

  // on refresh table
  onRefreshTable(): Observable<any> {
    return this.tableRefresh.asObservable();
  }

  // refresh calendar
  refreshCalendar(type) {
    this.calRefresh.next(type);
  }

  // on refresh calendar
  onRefreshCalendar(): Observable<any> {
    return this.calRefresh.asObservable();
  }

  // refresh event details
  refreshEvent() {
    this.eventRefresh.next(true);
  }

  onRefreshlabels(): Observable<any> {
    return this.refreshLabel.asObservable();
  }

  //refresh label details
  refreshLabels(name) {
    this.refreshLabel.next(name);
  }

  // on refresh event
  onRefreshEvent(): Observable<any> {
    return this.eventRefresh.asObservable();
  }

  // refresh event details
  refreshNavbarSearch(isAdvance) {
    this.navBarSubject.next(isAdvance);
  }

  onChangeNavbarSearch(): Observable<any> {
    return this.navBarSubject.asObservable();
  }

  // default overlay data
  defaultOverlayData() {
    return {
      type:   '',
      params: []
    };
  }

  // refresh OverLay
  refreshOverLay(overLayName) {
    this.overLayRefresh.next(overLayName);
  }

  // on refresh table
  onRefreshOverLay(): Observable<any> {
    return this.overLayRefresh.asObservable();
  }

  // open overlay popup
  openOverlay(_type, _params: any = []) {
    this.overlayData = {
      type:   _type,
      params: _params
    };
    this.overlayType.next(this.overlayData);
    $('body').addClass('disabled-scroll');
  }

  // close overlay
  closeOverlay() {
    this.overlayData = this.defaultOverlayData();
    this.overlayType.next(this.overlayData);
    $('body').removeClass('disabled-scroll');
  }

  // watch storage for current overlay
  currentOverlay(): Observable<any> {
    return this.overlayType.asObservable();
  }
}
