import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { API_URL, HttpRequestService } from 'src/app/core';

@Component({
  selector: 'app-view-transaction-popup',
  templateUrl: './view-transaction-popup.component.html',
  styleUrls: ['./view-transaction-popup.component.scss']
})
export class ViewTransactionPopupComponent implements OnInit {
  params;
  walletData: any[] = [];
  isLoader: boolean = false;
  currency;

  constructor(
    private popupService: BsModalService,
    private httpRequestService: HttpRequestService
  ) { }

  ngOnInit(): void {
    const settings = JSON.parse(localStorage.getItem('settings'));
    this.currency = settings.code;
    this.getSingleTransaction();
  }

  getSingleTransaction() {
    this.isLoader = true;
    const url = '';
    this.httpRequestService.getData(url, {}).subscribe(
      (resp: any) => {
        this.walletData = resp.record;
        this.isLoader = false;
      }, (error) => {

      }
    )
  }

  close() {
    this.popupService.hide();
  }
}
