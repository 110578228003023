<!-- The Modal -->
    <div class="InvoicesModal">
  
        <!-- Modal Header -->
        <div class="modal-header">
          <h5 *ngIf="!params?.type" class="modal-title">Create new cuisine</h5>
          <h5 *ngIf="params?.type" class="modal-title">Edit cuisine</h5>
          <button type="button" class="close" (click)="close()">&times;</button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
          <div class="cuisine-in">
            <form class="" [formGroup]="formGroup">
  
              <div class="form-in">
                <label>Cuisine name</label>
                <input type="text" formControlName="name" />
                <div
                *ngIf="formGroup.get('name').invalid && formGroup.get('name').touched">
                <span class="error"
                    *ngIf="formGroup.get('name').hasError('required')">Cuisine name is
                    required</span>
            </div>
              </div>
  
              <div class="text-right">
                <button class="btn next d-none">Save</button>
                <button class="btn next" [disabled]="loading.form" [ngClass]="{'loader': loading.form}" (click)="submit()">Save</button>
              </div>
            </form>
          </div>
        </div>
  
  
    </div>
  