import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoggerService } from '../../core/logger.service';
import { environment } from '../../../environments/environment';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions, UploadStatus } from 'ngx-uploader';

@Component({
  selector:     'file-uploader',
  templateUrl:  './file-uploader.component.html',
  styleUrls:    ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  @Input() accept = '';
  @Input() allowedExtension = [];
  @Input() uploaded: any[] = [];
  @Input() preview: boolean = true;
  @Input() canFeature: boolean = false;
  @Input() uploadType: string = 'image';
  @Input() type: string = 'eventImage';
  @Input() maxUpload: number = 10;
  @Input() maxAllow: number = 3;
  @Input() fileSize: number = 5242880;
  @Input() fileSizeError = 'Please upload file less than 5MB';
  @Input() fileTypes: string[] = ['image/*'];
  @Input() position: string = 'bottom';
  @Input() textName; string = '';
  @Input() isImageUpLoaded: boolean;
  @Output() deleted: EventEmitter<any> = new EventEmitter();
  @Output() featured: EventEmitter<any> = new EventEmitter();
  @Output() onUploaded: EventEmitter<any> = new EventEmitter();

  appId;
  apiUrl = environment.API_END_POINT;
  filteredImage: any;
  uplaodUrl: string;
  formData: FormData;
  dragOver: boolean;
  files: UploadFile[];
  options: UploaderOptions;
  humanizeBytes: Function;
  uploadInput: EventEmitter<UploadInput>;
  fileType: any;
  constructor(
    private toastr: ToastrService,
    private logger: LoggerService) { }

  ngOnInit() {
    const loginUser = JSON.parse(localStorage.getItem('userDetails'));
    this.appId = loginUser.appId,
      this.filteredImage = this.allowedExtension.find(item => item == 'gif');
    this.files = [];
    this.options = {
      concurrency: 1,
      maxUploads: this.maxUpload,
    };
    this.humanizeBytes = humanizeBytes;
    this.uplaodUrl = `${this.apiUrl}common/uploadFile`;
    this.uploadInput = new EventEmitter<UploadInput>();
  }

  ngOnChanges(changes: SimpleChanges) {
    const images = changes.uploaded?.currentValue;
    if (images && images.length) {
      this.uploaded = images;
    }
  }

  get isImage() {
    return (this.imageUrl.length) ? true : false;
  }

  get imageUrl() {
    return (this.position == 'top' && this.uploaded.length) ? this.uploaded[this.uploaded.length - 1].location : '';
  }

  /**
   * On upload output
   * @param output
   */
  onUploadOutput(output: UploadOutput): void {
    if (this.allowedExtension.length) {
      if (output.file) {
        this.fileType = output.file.type.split('/').pop().toLowerCase();
        if (this.allowedExtension.length) {
          if (this.allowedExtension.includes(this.fileType)) {
          } else {
            this.toastr.error('Wrong file format. Only Allowed ' + this.allowedExtension.join(', '), 'Error');
            return;
          }
        }
      }
    }

    if (this.allowedExtension.includes(this.fileType)) {
      if (output.type === 'allAddedToQueue') {

        if (this.files.length) {
          const event: UploadInput = {
            type: 'uploadAll',
            url: this.uplaodUrl,
            method: 'POST',
            data: {type: this.uploadType},
            // file:    file,
          };
          this.uploadInput.emit(event);
        }
      } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
        if (output.file.size > this.fileSize) {
          this.toastr.error(this.fileSizeError);
        }
        else {
          this.files.push(output.file);
        }
      } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
        const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
        this.files[index] = output.file;
      } else if (output.type === 'cancelled' || output.type === 'removed') {
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
      } else if (output.type === 'dragOver') {
        this.dragOver = true;
      } else if (output.type === 'dragOut') {
        this.dragOver = false;
      } else if (output.type === 'drop') {
        this.dragOver = false;
      } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
        this.logger.log(output.file.name + 'rejected');
      } else if (output.type === 'done') {
        this.files.forEach(file => {
          if (file.response) {
            let resp = file.response;
            if (resp.data) {
              let respData = resp.data;
              if (this.fileType === 'csv') {
              }
              this.uploaded.push(respData);
              if (this.uploaded.length) {
                this.isImageUpLoaded = true;
                this.uploaded.map(image => {
                });
              }
              this.onUploaded.emit(this.uploaded);
            }
          }
        });
      }
    }
    this.files = this.files.filter(file => file.progress.status !== UploadStatus.Done);
  }

  onChangeFile(files) {
    if (this.maxAllow == 1) {
      this.uploaded = [];
    }
    files = [];
    const file = files[0];
    if (file) {
      const fileType = file.type.split('/').pop().toLowerCase();
      if (this.allowedExtension.length) {
        if (this.allowedExtension.includes(fileType)) {
        } else {
          this.toastr.error('Wrong file format. Only Allowed ' + this.allowedExtension.join(', '), 'Error');
        }
      }
    }
  }
  
  /**
   * Cancel upload
   * @param id
   */
  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  /**
   * Set Featured Image
   * @param imageId
   */
  setFeatured(singleImage) {
    this.uploaded.map(_image => { _image.isFeatured = false; return _image; });
    singleImage.isFeatured = true;
    this.featured.emit(singleImage);
  }

  /**
   * Delete single file
   * @param fileId
   */
  deleteFile(idx, fileId) {
    const confirmed = confirm('Are you sure, you want to delete this file?');
    if (confirmed) {
      this.uploaded = this.uploaded.filter(_upload => _upload.id != fileId);
      this.deleted.emit({ idx, fileId });
    }
    else {
      return false;
    }
  }

}
