import {Action} from '@ngrx/store';

export const STEP_DATA_CONSTANTS = {
  SAVE_STEP_DATA: '[Step Detail] Save',
  RESET_STEP_DETAIL: '[Step Detail] Reset',
};


class SaveStepData implements Action {
  readonly type = STEP_DATA_CONSTANTS.SAVE_STEP_DATA;
  constructor(public payload: any) {
  }
}

class ResetStepData implements Action {
  readonly type = STEP_DATA_CONSTANTS.RESET_STEP_DETAIL;
}

export const StepDataActions = {
  saveDetail: SaveStepData,
  restDetails: ResetStepData
};




export const StepDataSelectors = {
  detail: (state: any) => state._step,
};

export type StepDataActionTypes = SaveStepData;
