export const API_URL = {
  // user
  _POST_SIGNUP: 'user/signUp',
  _GET_GUEST_TOKEN: 'user/getGuestToken',
  _POST_PHONE_EXIST: 'user/phoneExist',
  _POST_PHONE_VERIFICATION_CODE: 'user/sendPhoneVerificationCode',
  _POST_EDIT_PHONE_VERIFICATION_CODE: 'user/sendEditPhoneVerificationCode',
  _POST_EDIT_PHONE_NUMBER: 'user/editPhoneNumber',


  _POST_LOGIN: 'admin/login',
  _POST_FORGOT_PASSWORD: 'admin/forgotPassword',
  _POST_RESET_PASSWORD: 'admin/resetPassword',
  _GET_PROFILE_DETAILS: 'admin/profile',
  _POST_CHANGE_PASSWORD: 'admin/changePassword',
  _GET_ALL_NOTIFICATIONS: 'admin/notifications',
  _POST_NOTIFICATION_READ: 'admin/notificationRead',
  _GET_ALL_SERVICES: 'admin/services',
  _POST_CREATE_PLAN: 'admin/plan',
  _GET_CURRENCIES: 'admin/currencies',
  _GET_ALL_COUNTRIES: 'common/countries',
  _GET_ALL_PLANS: 'admin/plans',
  _GET_ALL_LEAD_REQUEST: 'admin/leads',
  _GET_ALL_MERCHANTS: 'admin/vendors',
  _GET_MERCHANT_BRANDS: 'vendor/brands',
  _GET_CUISINS: 'admin/cuisines',
  _GET_INVOICES: 'admin/invoices',
  _GET_INVOICE_PDF: 'admin/invoice/pdf',
  _GET_BLOGS: 'admin/blogs',
  _GET_CHARGES: 'admin/charges',
  _GET_SALECHANNELS: 'admin/saleChannels',
  _POST_CUISINS: 'admin/cuisine',
  _POST_LEAD_UPDATE: 'admin/leadUpdate',
  _POST_UPDATE_STATUS: 'admin/updateStatus',
  _POST_UPDATE_STATUS_REQ: 'admin/updateStatusReq',
  _POST_DELETE_RECORD: 'admin/deleteRecord',
  _POST_CREATE_CURRENCY: 'admin/currency',
  _POST_CREATE_MANAGER: 'admin/manager',
  _POST_CREATE_CHARGE: 'admin/charges',
  _POST_BLOG: 'admin/blog',
  _GET_ALL_MANAGERS: 'admin/managers',
  _POST_ASSIGN_USER: 'admin/lead/assignAgent',
  _GET_DATA_AFTER_REORDERING: 'admin/plan/reorder',
  _GET_ANALYTICS: 'admin/analytics',
  _GET_ANALYTICS_EARNINGS: '',
  _GET_ALL_CONTACT_REQUESTS: 'admin/contactRequests',
  _GET_MARKETING_REQUESTS: 'admin/marketingRequests',
  _GET_SETTINGS: 'admin/settings',
  _GET_MARKETING_TYPE: 'admin/marketChannels',
  _GET_BRAND_OUTLET_DETAILS: 'admin/vendor/brands',
  _GET_ALL_FAQS: 'admin/faqs',
  _POST_FAQs: 'admin/faq',
  _GET_REPORTS: 'admin/revenueReport',
  _GET_DASHBOARD_PLANS: 'admin/plansDashboard',
  _GET_REVENUE_DASHBOARD: 'admin/revenueDashboard',
  _POST_LOGOUT: 'admin/logout',
  _GET_ROLES: 'admin/roles',
  _POST_ROLE: 'admin/role',
  _GET_ALL_PERMISSIONS: 'admin/permissions',
  _EXTEND_VENDOR_PLAN: 'admin/vendor/extendPlan'
};