import {Action} from '@ngrx/store';

export const LOCATION_DATA_CONSTANTS = {
  SAVE_LOCATION_DETAIL: '[Location Detail] Save',
  RESET_LOCATION_DETAIL: '[Location Detail] Reset',
};


class SaveLocationDetail implements Action {
  readonly type = LOCATION_DATA_CONSTANTS.SAVE_LOCATION_DETAIL;

  constructor(public payload: any) {
  }
}

class ResetLocationDetail implements Action {
  readonly type = LOCATION_DATA_CONSTANTS.RESET_LOCATION_DETAIL;
}

export const LocationDataActions = {
  saveDetail: SaveLocationDetail,
  restDetails: ResetLocationDetail
};

export const LocationDataSelectors = {
  detail: (state: any) => state._region,
};

export type LocationDataActionTypes = SaveLocationDetail ;
