import {AfterViewInit, Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[numberOnly]'
})
export class NumberOnlyDirective implements AfterViewInit {

  constructor(private el: ElementRef) {
    // alert(this.decimalPlace);
  }

  @Input() decimalPlace = 6;
  @Input() maxLength = 10;
  @Input() maxValue = null;

  private regex: RegExp;
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', 'Delete', 'ArrowLeft', 'ArrowRight'];


  ngAfterViewInit(): void {
    let reg: string;
    if (!isNaN(this.decimalPlace) && this.decimalPlace != null) {
      if (this.decimalPlace !== 0) {
        reg = '^[0-9,]{0,' + this.maxLength + '}(\\.[0-9]{0,' + this.decimalPlace + '})?$';
      } else {
        reg = '^[0-9,]{0,' + this.maxLength + '}$';
      }
      this.regex = new RegExp(reg);
    } else {
      this.regex = new RegExp(/^-?[0-9,]*(\\.[0-9]*){0,1}$/g);
    }
    this.regex = new RegExp(reg);
  }

  // private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
  // Allow decimal numbers and negative values

  @HostListener('keydown', ['$event'])

  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current + event.key;
    // next = next.replace(',', '');
    const numberNext = next.replace(',', '');
    if (this.maxValue !== null) {
      if (+numberNext > this.maxValue) {
        return false;
      } else {
        if (next && !String(next).match(this.regex)) {
          event.preventDefault();
        }
      }
    }

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }

  }

  @HostListener('mousewheel', ['$event'])
  onMouseWheel(event: KeyboardEvent) {
    event.preventDefault();
  }

}
