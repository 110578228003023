import {AREA_DATA_CONSTANTS, AreaDataActionTypes} from '../actions';


const initialState = [];

export function AreaDataReducer(state = initialState, action: AreaDataActionTypes) {
  switch (action.type) {
    case AREA_DATA_CONSTANTS.SAVE_AREA_DATA:
      return action.payload;

    case AREA_DATA_CONSTANTS.RESET_AREA_DETAIL:
      return initialState;

    default:
      return state;
  }
}
