import {Action} from '@ngrx/store';

export const BRAND_DATA_CONSTANTS = {
  SAVE_BRAND_DATA: '[Brand Detail] Save',
  RESET_BRAND_DETAIL: '[Brand Detail] Reset',
};


class SaveBrandData implements Action {
  readonly type = BRAND_DATA_CONSTANTS.SAVE_BRAND_DATA;
  constructor(public payload: any) {
  }
}

class ResetBrandData implements Action {
  readonly type = BRAND_DATA_CONSTANTS.RESET_BRAND_DETAIL;
}

export const BrandDataActions = {
  saveDetail: SaveBrandData,
  restDetails: ResetBrandData
};




export const BrandDataSelectors = {
  detail: (state: any) => state._brand,
};

export type BrandDataActionTypes = SaveBrandData;
