<header>

    <nav class="navbar navbar-expand-sm">
      <!-- Brand/logo -->
      <a class="navbar-brand" href="javascript: //">Delivery Papa</a>
      
      <!-- Links -->
      <ul class="navbar-nav single-li">
        <li>Already have an account? 
          <a class="nav-a" href="javascript: //" (click)="logIn()">Log in</a>
        </li>
      </ul>
    </nav>
  
  </header>
