
import {USER_DATA_CONSTANTS, UserDataActionTypes} from '../actions';


const initialState = {};

export function UserDataReducer(state = initialState, action: UserDataActionTypes) {
  switch (action.type) {
    case USER_DATA_CONSTANTS.SAVE_USER_DATA:
      return {
        ...state,
        ...action.payload
      };

    case USER_DATA_CONSTANTS.RESET_USER_DETAIL:
      return initialState;

    default:
      return state;
  }
}
