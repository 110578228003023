<!-- The Modal -->
<!-- <div class="modal manageFunds fade" id="Transactions"> -->
    <div class="InvoicesModal">
        <!-- <div class="modal-content"> -->
    
          <!-- Modal Header -->
          <div class="modal-header">
            <h5 class="modal-title">Change Plan</h5>
            <button type="button" class="close">&times;</button>
          </div>
    
          <!-- Modal body -->
          <div class="modal-body">
            <div class="transactionBody">
              <table>
                <thead>
                  <tr>
                    <th>INVOICE ID</th>
                    <th>PLAN</th>
                    <!-- <th>PAID</th>
                    <th>DATE</th> -->
                    <th></th>
                  </tr>
                </thead>
    
                <tbody>
                  <tr *ngFor="let plan of allPlans">
                      <td>{{ plan._id }}</td>
                      <td>{{ plan.name }}</td>
                      <td><div class=""><a href="javascript: //">Select Plan</a></div></td>
                  </tr>
              
                </tbody>
              </table>
            </div>
          </div>
    
    
        <!-- </div> -->
      </div>
    <!-- </div> -->
