import {ValidationErrors} from '@angular/forms';
import {HttpParams} from '@angular/common/http';
import * as moment from 'moment';
import {UpperCasePipe, TitleCasePipe} from '@angular/common';
import {environment} from '../../environments/environment';
import { DATE_ATR } from '../directives/listing-table/listing-table.model';


declare var $: any;

export class Utils {

  // check if dev mode
  public static isDevMode() {
    return !environment.production;
  }

  /**
   * Capitalize Value
   * @param value
   * @param limit
   */
  public static capitalize(value, limit?) {
    const tcasePipe = new TitleCasePipe();
    const capitalize = tcasePipe.transform(value);
    let trimmed = capitalize.replace(/\s\s+/g, ' ');
    if (trimmed.length >= limit) {
      trimmed = trimmed.slice(0, limit);
    }
    return trimmed;
  }

  /**
   * Capitalize Name
   * @param value
   * @param limit
   */
  public static capitalizeName(value, limit?) {
    let trimmed = value.replace(/\s\s+/g, ' ');
    if (trimmed.length > 1) {
      trimmed = trimmed.substring(0, 1).toUpperCase() + trimmed.substring(1);
    }
    if (trimmed.length >= limit) {
      trimmed = trimmed.slice(0, limit);
    }
    return trimmed;
  }

  /**
   * Set value uppercase
   * @param value
   * @param limit
   */
  public static uppercase(value, limit?) {
    const ucasePipe = new UpperCasePipe();
    const uppercase = ucasePipe.transform(value);
    let trimmed = uppercase.replace(/\s\s+/g, ' ');
    if (trimmed.length >= limit) {
      trimmed = trimmed.slice(0, limit);
    }
    return trimmed;
  }

  /**
   * clone fresh object
   * fix shallow copy
   * @param obj
   */
  public static cloneObj(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * Print Validation Errors
   * @param error 
   */
  public static printErrors(error) {
    let errorMessage = ''; 
    if(error?.error?.code == 'VALIDATION_ERROR') {
      let allErrors = error.error.error;
      for(let _errorKey of Object.keys(allErrors)) {
        errorMessage += `\n${_errorKey.toUpperCase()} : ${allErrors[_errorKey]}\n`;
      }
    }
    else {
      errorMessage = error?.error?.message;
    }
    return errorMessage;
  }

  /**
   * Google Address
   * @param address
   */
  public static googleAddress(address) {
    const faddress = address.formatted_address;
    const location = address.geometry.location;
    const latitude = location.lat();
    const longitude = location.lng();
    let cityName = '';
    let stateName = '';
    let countryName = '';
    let postalCode = '';

    address.address_components.forEach((addcomp) => {
      if (addcomp.types.includes('country')) {
        countryName = addcomp.long_name;
      }
      if (addcomp.types.includes('administrative_area_level_1')) {
        stateName = addcomp.long_name;
      }
      if (addcomp.types.includes('administrative_area_level_2')) {
        cityName = addcomp.long_name;
      }
      if (addcomp.types.includes('postal_code')) {
        postalCode = addcomp.short_name;
      }
    });
    return {
      location, latitude, longitude, faddress, cityName, stateName, countryName, postalCode
    };
  }

  /**
   * Make array unique
   * @param arr
   */
  public static uniqueArray(arr) {
    return arr.filter((x, i) => {
      return x != undefined && x.length > 0 && arr.indexOf(x) === i;
    });
  }

  /**
   * Make object array Unique
   * based on property name
   * @param array
   * @param propName
   */
  public static uniqueArrayObj(array, propName) {
    array = array ? array : [];
    return array.filter((e, i) => array.findIndex(a => a[propName] === e[propName]) === i);
  }

  /**
   * Slugify
   * @param str
   */
  public static slugify(str) {
    const slug = str.trim().replace(/[^a-z0-9-]/gi, '-').replace(/-+/g, '-').replace(/^-|-$/g, '');
    return slug.toLowerCase();
  }

  /**
   * Check if string contain URL
   * @param str
   */
  public static hasUrl(str) {
    return new RegExp('([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?').test(str);
  }

  /**
   * Sort array
   */
  public static sortArray(arr) {
    const sorted = arr.sort((a, b) => a > b ? 1 : -1);
    return sorted;
  }

  // refresh selectpicker
  public static refreshSelectpicker(selector = '.selectpicker', empty = false) {
    setTimeout(() => {
      const selectPicker = $(selector);
      // set empty value in selectpicker
      if (empty) {
        selectPicker.val('').selectpicker('refresh');
      }
      selectPicker.selectpicker('refresh');
      $('.bs-searchbox .form-control').attr('autocomplete', 'nope');
    }, 10);
  }

  // refresh all select boxes
  public static refreshAllSelectBoxes(selector = 'select') {
    setTimeout(() => {
      const selectBoxes = $(selector);
      if (selectBoxes.length) {
        selectBoxes.each((_index, _selbox) => {
          if (!$(_selbox).val()) {
            $(_selbox).val('');
          }
        });
      }
      // set selectpickers
      const selectPickers = $('.selectpicker');
      selectPickers.selectpicker('refresh');
    }, 10);
  }

  // enable selectpicker
  public static enableSelectpicker(selector, empty = false) {
    setTimeout(() => {
      const selectPicker = $(selector).removeAttr('disabled');
      if (empty) {
        selectPicker.val('');
      }
      selectPicker.selectpicker('refresh');
    }, 10);
  }

  // disable selectpicker
  public static disableSelectpicker(selector, empty = false) {
    setTimeout(() => {
      const selectPicker = $(selector).attr('disabled', true);
      if (empty) {
        selectPicker.val('');
      }
      selectPicker.selectpicker('refresh');
    }, 10);
  }

  /**
   * Get range of numbers
   * @param start
   * @param count
   */
  public static range(start, count) {
    return Array.apply(0, Array(count)).map((element, index) => {
      return index + start;
    });
  }

  /**
   * Log form errors
   * @param reactiveform
   */
  public static logFormErrors(reactiveform) {
    Object.keys(reactiveform.controls).forEach(key => {
      const controlErrors: ValidationErrors = reactiveform.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log(`${key} => ${keyError},`, `Error Value:`, controlErrors[keyError]);
        });
      }
    });
  }

  /**
   * Convert Params
   */
  public static parseQueryParams(query) {
    let params: HttpParams = new HttpParams();
    for (const key of Object.keys(query)) {
      if (query[key]) {
        if (query[key] instanceof Array) {
          query[key].forEach((item) => {
            params = params.append(`${key.toString()}[]`, item);
          });
        } else {
          params = params.append(key.toString(), query[key]);
        }
      } else {
        params = params.append(key.toString(), query[key]);
      }
    }
    return params;
  }

  /**
   * Return date formats
   * @param withTime
   */
  public static dateFormats(withTime = true) {
    return {
      parseInput: 'LL LT',
      fullPickerInput: (withTime) ? 'MM/DD/YYYY hh:mm A' : 'MM/DD/YYYY',
      datePickerInput: (withTime) ? 'MM/DD/YYYY hh:mm A' : 'MM/DD/YYYY',
      timePickerInput: 'hh:mm A',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    };
  }

  /**
   * Get days diff from current date
   * @param timestamp
   */
  public static daysDiff(timestamp) {
    return moment.unix(timestamp).diff(new Date(), 'days');
  }

  /**
   * Check if small screen
   */
  public static isSmallScreen(breakPoint = 768) {
    return (window.innerWidth < breakPoint);
  }

  // show pricing in decimals
  public static financial(x) {
    return Number.parseFloat(x).toFixed(2);
  }

  // thousand separator value
  public static thousandValue(val) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  /**
   * Convert Object to Array
   * @param objectData
   */
  // public static parseObjectToArray(objectData, allFields = false) {
  //   const parsedArray = [];
  //   if (objectData && Object.keys(objectData).length > 0) {
  //     Object.values(objectData).forEach((_object: any) => {
  //       // send only selected vendors
  //       parsedArray.push(_object);
  //     });
  //   }
  //   return parsedArray;
  // }

  /**
   * Convert Date to UTC Time
   * @param date
   */
  static getUTCTimeString(date: Date): string {
    // console.log(date.getUTCHours().toString() + ':' + date.getUTCMinutes().toString() + ' ' + ((date.getUTCHours() < 12) ? 'AM' : 'PM'));
    return ((date.getUTCHours() < 12) ? date.getUTCHours().toString() : (date.getUTCHours() - 12).toString()) + ':' + date.getUTCMinutes().toString() + ' ' + ((date.getUTCHours() < 12) ? 'AM' : 'PM');
  }

  /**
   * Convert Date to Local Time
   * @param date
   */
  static getLocalTimeString(date: Date): string {
    const hours = ((date.getHours() < 12) ?
      ((date.getHours() < 10) ? ('0' + date.getHours()) : (date.getHours().toString())) :
      (((date.getHours() - 12) < 10) ? ('0' + ((date.getHours() - 12).toString())) : (date.getHours() - 12).toString()));
    const minutes = ((date.getMinutes() < 10) ? ('0' + date.getHours().toString()) : (date.getHours().toString()));
    return (hours + ':' + minutes + ' ' + ((date.getHours() < 12) ? 'AM' : 'PM'));
  }

  /**
   * Convert Object to Array
   * @param objectData
   */
  public static parseObjectToArray(objectData, allFields = false) {
    let parsedArray = [];
    if (objectData && Object.keys(objectData).length > 0) {
      Object.values(objectData).forEach((_object: any) => {
        // send only selected vendors
        parsedArray.push(_object);
      });
    }
    return parsedArray;
  }

  /**
   * Return UTC date
   * @param paramDate
   * @param atr
   */
  public static getUTCDate(paramDate, atr: DATE_ATR) {
    if (paramDate) {
      const date: Date = new Date(paramDate);
      const utcDate: Date = new Date();
      utcDate.setDate(date.getDate());
      utcDate.setMonth(date.getMonth());
      utcDate.setFullYear(date.getFullYear());
      if (atr === DATE_ATR.START) {
        utcDate.setHours(0);
        utcDate.setMinutes(0);
        utcDate.setSeconds(0);
        utcDate.setMilliseconds(0);
      }
      if (atr === DATE_ATR.END) {
        utcDate.setHours(23);
        utcDate.setMinutes(59);
        utcDate.setSeconds(59);
        utcDate.setMilliseconds(999);
      }
      return moment(utcDate).utc(true).format();
    } else {
      return null;
    }
  }

  /**
   * Short string
   * @param str 
   * @param len 
   */
  public static shortString(str, len=30) {
    if(str && str.length >= len){
      return str.substring(0, len) + ' ...'; 
    }else{
      return str;
    }
  }

  // visibility method
  public static isVisible(permissions,slug){
    if(permissions && permissions.length){
      return permissions.includes(`${slug}`);
    }else{
      return true;
    }
  }
}
