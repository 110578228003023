<div class="manageFunds" id="Transactions">
    <app-loader *ngIf="loading.api"></app-loader>

    <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
    <h3>Lead details</h3>
    <div class="modal-body lead-details-modal px-0" *ngIf="!loading.api">
        <div class="lead-details">
            <div class="">
                <p class="sm m16"><span>Name:</span>{{ params?.name }}</p>
                <p class="sm m16"><span>Email:</span>{{ params?.email }}</p>
                <p class="sm m16"><span>Mobile number:</span>{{ params?.phone }} </p>
                <p class="sm m16"><span>Country:</span>{{ params?.country }}</p>
                <p class="sm m16"><span>Number of Outlets:</span>{{ params?.outlets }}</p>
                <div *ngIf="params?.status !== 'New'" class="sm m16 p"><span>Agreed Price:</span>
                    <a class="">{{ params?.price ? params?.price : '--' }}</a>
                </div>

                <p *ngIf="params?.status !== 'New'" class="sm m16 d-flex"><span>Reason:</span>{{ params?.notes }}</p>
                <p *ngIf="params?.status !== 'New'" class="sm m30"><span>Status:</span>{{ params?.status
                    }}</p>

                <div class="changePlan_modal">
                    <p class="sm m16">
                        <span>Plan subscribe:</span>
                    </p>
                    <div class="chngeolan">
                        <p class="lg"> ({{ params?.plan?.name }}) {{currency}} {{ params?.plan?.price }}
                            <b> /{{ params?.plan?.durationType }}</b>
                        </p>

                        <!-- <p *ngIf="formGroupPlan.get('plan').value" class="lg">{{currency}} {{getSelectedPlan}} {{selectedPrice}}
                            <b> /{{selectedDurationType}}</b>
                        </p> -->
                        <div class="planChange-in"
                            *ngIf="params?.status == 'New' || params?.status == 'Contacted' ||  params?.status == 'In Bargain'">
                            <a href="javascript: //" (click)="changeStatus = !changeStatus;" *hasAccess="['leads-edit']">Change plan</a>
                            <form class="border-0 pt-0" [formGroup]="formGroupPlan" (change)="onSelectPlan()" *ngIf="changeStatus">
                                <div class="contacted-p">
                                    <!-- <p class=""><span>Set Status</span></p> -->
                                    <div class="selectDiv">
                                        <select formControlName="plan">
                                            <option *ngFor="let plan of allPlans" [value]="plan._id">{{plan?.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>

                <div class="changePlan_modal" *ngIf="params?.status == 'Confirmed'">
                    <p class="sm m16">
                        <span>Change Status:</span>
                    </p>
                    <div class="chngeolan">
                        <!-- <p class="lg"> Paid</b> -->
                        <!-- </p> -->

                        <div class="">
                            <form [formGroup]="formGroupChangeStatus">
                                <div class="contacted-p mb-3">
                                    <div class="selectDiv">
                                        <select formControlName="plan" (change)="changePlanStatus($event)">
                                            <option *ngFor="let status of paidStatus" [value]="status._id">{{status?.name}}
                                            </option>
                                        </select>
                                        <div *ngIf="formGroup.get('status').invalid && formGroup.get('status').touched">
                                            <span class="error" *ngIf="formGroup.get('status').hasError('required')">Status is
                                                required</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="">
                                    <div class="form-in">
                                        <!-- <label class="">Description</label> -->
                                        <textarea type="text" formControlName="notes" rows="3" placeholder="Your comment"></textarea>
                                        <div *ngIf="formGroupChangeStatus.get('notes').invalid && formGroupChangeStatus.get('notes').touched">
                                            <span class="error" *ngIf="formGroupChangeStatus.get('notes').hasError('required')">Reason is
                                                required</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-right">
                                    <button type="button" class="btn next" [ngClass]="{'loader': loading.form}"
                                        (click)="submitStatus()">Submit</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

            <div class="lead-history">
                <h5 class="m-16">Lead History</h5>

                <h6 *ngIf="params?.history?.length === 0" class="f14 m-16">No record found</h6>

                <div class="lead-div" *ngFor="let history of params?.history">
                    <div class="lh-status">
                        <label class="pending"
                            [ngClass]="{'new': history?.status === 'NEW','pending': history?.status === 'INBARGAIN', 'completed': history?.status === 'PAID_BY_ADMIN',
                    'accepted': history?.status === 'CONFIRMED','rejected': history?.status === 'DECLINED', 'contacted': history?.status === 'CONTACTED'}">{{
                            history?.status === 'INBARGAIN' ? 'In Bargain' : history?.status }}</label>
                        <p class="f12">{{ history?.updatedAt | date: 'medium'}} </p>
                    </div>

                    <div class="lead-comment">
                        <p>{{ history?.notes }} </p>
                    </div>

                    <div class="lead-agent">
                        <h6 *ngIf="history?.status === 'CONFIRMED' || history?.status === 'CONTACTED' || history?.status === 'DECLINED' || history?.status === 'INBARGAIN' || history?.status === 'PAID_BY_ADMIN'"
                            class="f12 m-12">Offer Price: <b class="fw600">{{currency}} {{
                                history?.price }}</b></h6>
                        <h6 *ngIf="history?.status === 'CONFIRMED' || history?.status === 'CONTACTED' || history?.status === 'DECLINED' || history?.status === 'INBARGAIN'"
                            class="f12 ">Agent Name: <b class="fw600">{{ history?.agentName
                                }}</b></h6>
                    </div>
                </div>

            </div>
        </div>

        <form [formGroup]="formGrouptoken" *ngIf="isToken && params?.status === 'Confirmed'">
            <div class="form-in">
                <input type="text" #userinput formControlName="link">

                <div class="copycode-lead">
                    <a href="javascript: //" class="copyCode" (click)="copyInputMessage(userinput)"
                        value="click to copy">
                        <span><img class="img-fluid" src="../../../../assets/images/copyIcon.svg">
                        </span>Copy link</a>
                </div>
            </div>
        </form>

        <div *ngIf="params?.status == 'New' || params?.status == 'Contacted' || params?.status == 'In Bargain'">
            <form [formGroup]="formGroup" *hasAccess="['leads-edit']">
                <div class="contacted-p">
                    <p class=""><span>Set Status</span></p>
                    <div class="selectDiv">
                        <select formControlName="status" (change)="changeLeadStatus()">
                            <option value="" selected>Select Status</option>
                            <option [value]="st.id" [selected]="selected" *ngFor="let st of status">{{st.name}}
                            </option>
                        </select>

                        <div *ngIf="formGroup.get('status').invalid && formGroup.get('status').touched">
                            <span class="error" *ngIf="formGroup.get('status').hasError('required')">Status is
                                required</span>
                        </div>
                    </div>
                </div>

                <div class="form-in">
                    <label>Price </label>
                    <input type="number" formControlName="price" min="0" (keypress)="onlyNumbers($event)"
                        (focusout)="onFocusOut($event)" placeholder="E.g. 1" />
                    <div *ngIf="formGroup.get('price').invalid && formGroup.get('price').touched">
                        <span class="error" *ngIf="formGroup.get('price').hasError('required')">Price is
                            required</span>
                    </div>
                </div>

                <div class="">
                    <div class="form-in">
                        <label class="">Reason</label>
                        <textarea type="text" formControlName="notes" rows="3" placeholder="Your comment"></textarea>
                        <div *ngIf="formGroup.get('notes').invalid && formGroup.get('notes').touched">
                            <span class="error" *ngIf="formGroup.get('notes').hasError('required')">Reason is
                                required</span>
                        </div>
                    </div>
                </div>

                <div class="text-right">
                    <button type="button" class="btn next" [ngClass]="{'loader': loading.form}"
                        (click)="submit()">Submit</button>
                </div>
            </form>
        </div>
        <!-- </div> -->
    </div>