import {Directive, ElementRef, EventEmitter, OnInit, Output} from '@angular/core';

declare var google: any;

@Directive({
  selector: '[appGooglePlaceSearch]'
})
export class GooglePlaceSearchDirective implements OnInit {

  constructor(
    elementRef: ElementRef
  ) {
    // elementRef will get a reference to the element where
    // the directive is placed
    this.element = elementRef.nativeElement;
  }

  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  element: HTMLInputElement;
  isGoogle = false;

  ngOnInit() {

    if (google !== null && google !== undefined ) {
      this.initAutocomplete();
    } else {
      const timeinterval = setInterval(() => {
        if (this.isGoogle) {
          clearInterval(timeinterval);
        }else {
          if (google) {
            this.initAutocomplete();
          } else {
            this.isGoogle = false;
          }
        }

      }, 2000);
    }

  }

  initAutocomplete() {
    this.isGoogle = true;
    const autocomplete = new google.maps.places.Autocomplete(this.element, {
      // types: ['address'],
      sessionToken: new google.maps.places.AutocompleteSessionToken(),
    });
    // Event listener to monitor place changes in the input
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      // Emit the new address object for the updated place
      this.onSelect.emit(this.getFormattedAddress(autocomplete.getPlace()));
    });

  }


  getFormattedAddress(place) {
    // @params: place - Google Autocomplete place object
    // @returns: location_obj - An address object in human readable format
    const locationObj: any = {};
    if (place.geometry && place.geometry.location) {
      const location = place.geometry.location;
      locationObj.latitude = location.lat();
      locationObj.longitude = location.lng();
      place.address_components.forEach((item: any) => {
        locationObj.formatted_address = place.formatted_address;
        if (item.types.indexOf('locality') > -1) {
          locationObj.locality = item.long_name;
        } else if (item.types.indexOf('administrative_area_level_1') > -1) {
          locationObj.admin_area_l1 = item.short_name;
        } else if (item.types.indexOf('street_number') > -1) {
          locationObj.street_number = item.short_name;
        } else if (item.types.indexOf('route') > -1) {
          locationObj.route = item.long_name;
        } else if (item.types.indexOf('country') > -1) {
          locationObj.country = item.long_name;
        } else if (item.types.indexOf('postal_code') > -1) {
          locationObj.postal_code = item.short_name;
        }
      });
    }
    return locationObj;
  }
}
