import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class LoggerService {

  logger: boolean = environment.logger;

  // console log information
  log(...message: any) {
    if(this.logger) {
      console.log(...message);
    }
  }

  // console info message
  info(...message: any) {
    if(this.logger) {
      console.info(...message);
    }
  }

  // console warn message
  warn(...message: any) {
    if(this.logger) {
      console.warn(...message);
    }
  }
}