import {CART_DATA_CONSTANTS, CartDataActionTypes} from '../actions';


const initialState = {orderType: '', shop: '', items: [], finalOrder: {}};

export function CartDataReducer(state = initialState, action: CartDataActionTypes) {
  switch (action.type) {
    case CART_DATA_CONSTANTS.SAVE_CART_DATA:
      return {
        ...state,
        ...action.payload
      };

    case CART_DATA_CONSTANTS.RESET_CART_DETAIL:
      return initialState;

    default:
      return state;
  }
}
