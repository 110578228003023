import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HttpRequestService } from 'src/app/core';
import { FormValidator } from '../../validators';

@Component({
  selector: 'app-manage-refund-popup',
  templateUrl: './manage-refund-popup.component.html',
  styleUrls: ['./manage-refund-popup.component.scss']
})
export class ManageRefundPopupComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private httpRequestService: HttpRequestService,
    private popupService: BsModalService) { }

  formGroup: FormGroup = this.formBuilder.group({
    amount:      new FormControl('', [Validators.compose([Validators.required])]),
    transferType: new FormControl('',Validators.compose([Validators.required])),
    refundReason: new FormControl('',[Validators.compose([Validators.required])]),
    other: new FormControl('',[])
  });

  params;
  currency;
  allTransferTypes: any[] = [{
    id: 1,
    name: 'type 1'
  }];
  allRefundReason: any[] = [
    {
      id: 1,
      name: 'Reason 1'
    }
  ];

  ngOnInit(): void {
    const settings = JSON.parse(localStorage.getItem('settings'));
    this.currency = settings.code;
  }

  onlyNumbers(event){
    FormValidator.onlyNumbers(event);
  }

  submit(){
    this.popupService.hide();
    
    if(this.formGroup.valid){
      const url = '';
      const payload = this.formGroup.value;
      this.httpRequestService.postData(url,payload).subscribe(
        (resp: any)=>{

        },(error)=>{

        });
    }else{
      this.formGroup.markAllAsTouched();
    }
  }

  close(){
    this.popupService.hide();
  }

}
