import { Action } from '@ngrx/store';

export const APPLIEDFILTERS_CONSTANTS = {
  RESET_DATA:             '[Applied Filters] Reset',
  SAVE_TABLE_LISTING:     '[Applied Filters] Table Save',
  RESET_TABLE_LISTING:    '[Applied Filters] Table Reset'
};

class SaveTableListing implements Action {
  readonly type = APPLIEDFILTERS_CONSTANTS.SAVE_TABLE_LISTING;
  
  constructor(public payload: any) {}
}

class ResetTableListing implements Action {
  readonly type = APPLIEDFILTERS_CONSTANTS.RESET_TABLE_LISTING;
}

class ResetAppliedFilters implements Action {
  readonly type = APPLIEDFILTERS_CONSTANTS.RESET_DATA;
}

export const AppliedFiltersActions = {
  saveTableListing:     SaveTableListing,
  resetTableListing:    ResetTableListing,
  resetData:            ResetAppliedFilters
};

export const AppliedFiltersSelectors = {
  table_listing:   (state: any) => state._appliedFilters.table_listing
};

export type AppliedFiltersActionTypes = SaveTableListing;
