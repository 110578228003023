    <div class="InvoicesModal">
        <div class="modal-header">
          <h5 class="modal-title">Invoices {{ params?.name }}</h5>
          <button type="button" class="close" (click)="close()">&times;</button>
        </div>
  
        <div class="modal-body">
        <app-loader *ngIf="loading.api"></app-loader>

          <div class="transactionBody" id="contentToConvert">
            <table>
              <thead>
                <tr>
                  <!-- <th>INVOICE ID</th> -->
                  <th>PLAN</th>
                  <th>PAID</th>
                  <th>STATUS</th>
                  <th>DATE</th>
                  <th>DOWNLOAD</th>
                </tr>
              </thead>
  
              <tbody *ngIf="allInvoices && allInvoices.length && !loading.api">
                <tr *ngFor="let invoice of allInvoices">
                    <!-- <td>{{ invoice?._id }}</td> -->
                    <td>{{ invoice?.plan?.name }}</td>
                    <td>{{currency}} {{ invoice?.amount }}</td>
                    <td>{{invoice?.status }}</td>
                    <td>{{ invoice?.paidDate | date}}</td>
                    <td>
                      <div class="dlCSV">
                        <a href="javascript:void(0)" [ngClass]="{'invoice-loader': isCsvLoading}" (click)="downloadCSV(invoice._id)">CSV</a>
                        <a href="javascript:void(0)" [ngClass]="{'invoice-loader': isPdfLoading}"  (click)="downloadPDF(invoice._id)">PDF</a>
                      </div>
                    </td>
                </tr>
            
              </tbody>
              <div *ngIf="allInvoices.length === 0">
                <p class="m-48 text-center text mt-3">No Invoices found</p>
              </div>
            </table>
          </div>
        </div>
    </div>