import { Component, OnInit } from '@angular/core';
import { Utils } from 'src/app/core';
import { DpModalService } from 'src/app/services';
import { DpModalParams } from 'src/app/_modal/modal.params';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-listing-columns',
  templateUrl: './listing-columns.component.html',
  styleUrls: ['./listing-columns.component.scss']
})
export class ListingColumnsComponent implements OnInit {
  allColumns: any[];
  tableColumns: any[];

  constructor(
    private overlayParams: DpModalParams,
    private modalService: DpModalService) {
  }

  ngOnInit() {
    const overlayData = this.overlayParams.data;
    this.allColumns = Utils.cloneObj(overlayData.allColumns);
    this.tableColumns = overlayData.allColumns;
  }

  // remove column
  removeColumn(_index) {
    this.allColumns.splice(_index, 1);
  }

  // add new column
  addNewColumn() {
    this.tableColumns = this.allColumns;
  }

  // save columns
  saveColumns() {

    this.allColumns.forEach((item, ind) => {
      this.tableColumns[ind] = item;
    });
    this.close();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.allColumns, event.previousIndex, event.currentIndex);
  }

  /**
   * Close popup
   */
  close() {
    this.modalService.close('listingColumns');
  }

}
