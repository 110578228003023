import {Action} from '@ngrx/store';

export const OUTLET_DATA_CONSTANTS = {
  SAVE_OUTLET_DATA: '[Outlet Detail] Save',
  RESET_OUTLET_DETAIL: '[Outlet Detail] Reset',
};


class SaveOutletData implements Action {
  readonly type = OUTLET_DATA_CONSTANTS.SAVE_OUTLET_DATA;
  constructor(public payload: any) {
  }
}

class ResetOutletData implements Action {
  readonly type = OUTLET_DATA_CONSTANTS.RESET_OUTLET_DETAIL;
}

export const OutletDataActions = {
  saveDetail: SaveOutletData,
  restDetails: ResetOutletData
};




export const OutletDataSelectors = {
  detail: (state: any) => state._outlet,
};

export type OutletDataActionTypes = SaveOutletData;
