import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CurrentLocationPickerButtonModule, GooglePlaceSearchModule, NumberOnlyModule, SelectPickerModule, FileUploaderModule, BreadcrumbModule } from './directives';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AgmCoreModule } from '@agm/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { SharedModule } from './shared/shared.module';
import { JwtInterceptor } from './core/jwt.interceptor';
import { CartDataReducer, LocationDetailReducer, UserDataReducer, AppliedFiltersReducers } from './redux/reducers';
import {
} from './modals';
import { FooterComponent } from './common';
import { AuthenticationModule } from './authentication/authentication.module';
import { DeletePopupComponent } from './modals/delete-popup/delete-popup.component';
import { CreateCuisinePopupComponent } from './modals/create-cuisine-popup/create-cuisine-popup.component';
import { ManageRefundPopupComponent } from './modals/manage-refund-popup/manage-refund-popup.component';
import { PlanChangePopupComponent } from './modals/plan-change-popup/plan-change-popup.component';
import { LeadRequestPopupModule } from './modals/lead-request-popup/lead-request.module';
import { AssignAgentPopupModule } from './modals/assign-agent-popup/assign-agent-popup.module';
import { ChangePasswordPopupModule } from './modals/change-password-popup/change-password-popup.module';
import { MerchantInvoicePopupModule } from './modals/merchant-invoice-popup/merchant-invoice-popup.module';
import { MerchantDetailPopupModule } from './modals/merchant-detail-popup/merchant-detail-popup.module';
import { ContactDetailPopupComponent } from './modals/contact-detail-popup/contact-detail-popup.component';
import { ContactDetailPopupModule } from './modals/contact-detail-popup/contact-detail.module';
import { MarketingRequestsDetailPopupComponent } from './modals/marketing-requests-detail-popup/marketing-requests-detail-popup.component';
import { MarketingRequestsDetailPopupModule } from './modals/marketing-requests-detail-popup/marketing-requests-detail-popup.module';
import { ViewBrandsComponent } from './modals/view-brands/view-brands.component';
import { ViewBrandsModule } from './modals/view-brands/view-brands.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  const stateKeys = ['_region', '_appliedFilters'];
  return localStorageSync({ keys: stateKeys, rehydrate: true })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    DeletePopupComponent,
    // LeadRequestPopupComponent,
    CreateCuisinePopupComponent,
    ManageRefundPopupComponent,
    PlanChangePopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    SelectPickerModule,
    LeadRequestPopupModule,
    FileUploaderModule,
    BreadcrumbModule,
    HttpClientModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      autoDismiss: true,
      progressBar: true,
      timeOut: 2000
    }),
    NgxSkeletonLoaderModule,
    NumberOnlyModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD7f9oPC3ISpPjasAB3BxxjyIS2d38rQVg',
      libraries: ['places']
    }),
    StoreModule.forRoot({}, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: true
      }
    }),
    StoreDevtoolsModule.instrument(),
    StoreModule.forFeature('_currentUser', UserDataReducer),
    // StoreModule.forFeature('_cart', CartDataReducer),
    StoreModule.forFeature('_region', LocationDetailReducer),
    StoreModule.forFeature('_appliedFilters', AppliedFiltersReducers),
    CurrentLocationPickerButtonModule,
    GooglePlaceSearchModule,
    TrimValueAccessorModule,
    SharedModule,
    AssignAgentPopupModule,
    ChangePasswordPopupModule,
    AuthenticationModule,
    MerchantInvoicePopupModule,
    MerchantDetailPopupModule,
    ContactDetailPopupModule,
    MarketingRequestsDetailPopupModule,
    ViewBrandsModule,
    NgbModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
