<!-- The Modal -->
<div id="yesNo">
    <!-- <div class="modal-dialog ">
      <div class="modal-content"> -->
  
        <!-- Modal body -->
        <div class="modal-body">
          <form class="yesNoBody">
            <h5 class="fw500">Do you want to set your order status ready?</h5>
            <div class="btn-grp">
              <button type="button" class="btn sm" (click)="delete()">Yes</button>
              <button type="button" class="btn-white sm" (click)="cancel()">No</button>
            </div>
          </form>
        </div>
  
  
      <!-- </div>
    </div> -->
  </div>