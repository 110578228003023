import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [LoaderComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [LoaderComponent]
})
export class LoaderModule { }
