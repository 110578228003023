import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GooglePlaceSearchDirective} from './google-place-search.directive';

declare var google: any;


@NgModule({
  declarations: [
    GooglePlaceSearchDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [GooglePlaceSearchDirective]
})
export class GooglePlaceSearchModule { }
