import {Action} from '@ngrx/store';

export const USER_DATA_CONSTANTS = {
  SAVE_USER_DATA: '[user Detail] Save',
  RESET_USER_DETAIL: '[user Detail] Reset',
};


class SaveUserData implements Action {
  readonly type = USER_DATA_CONSTANTS.SAVE_USER_DATA;
  constructor(public payload: any) {
  }
}

class ResetUserData implements Action {
  readonly type = USER_DATA_CONSTANTS.RESET_USER_DETAIL;
}

export const UserDataActions = {
  saveDetail: SaveUserData,
  restDetails: ResetUserData
};




export const UserDataSelectors = {
  detail: (state: any) => state._currentUser,
};

export type UserDataActionTypes = SaveUserData;
