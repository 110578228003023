<div class="ticket-filters scrolling-blk paddingB80">
    <div class="column_title">
      <h4>Filters</h4>
      <span class="slide-close icon-arrow-long" (click)="close()"></span>
    </div>
    <form (ngSubmit)="filterRecords()" autocomplete="off">
      <div class="sidepanel-h">
  <!--      {{allFilters | json}}-->
        <div class="m-t40">
          <ng-container *ngFor="let singleFilter of allFilters">
            <ng-container [ngSwitch]="singleFilter.type">
              <ng-container *ngSwitchCase="'text'">
                  <div class="form-group m-b20">
                    <div class="fields">
                      <input type="text" [name]="singleFilter.name" [(ngModel)]="singleFilter.value" placeholder="Enter here">
                      <label>{{singleFilter.label}}</label>
                    </div>
                  </div>
              </ng-container>
  
              <ng-container *ngSwitchCase="'date'">
                  <div class="form-group m-b20">
                    <div class="fields" [ngClass]="{'focused': singleFilter.value}" >
                      <input type="text" [name]="singleFilter.name" [(ngModel)]="singleFilter.value" [owlDateTime]="pickDateRange" [owlDateTimeTrigger]="pickDateRange" placeholder="MM/DD/YYYY" (ngModelChange)="updateDatePicker($event, singleFilter)" placeholder="Enter here" readonly>
                      <owl-date-time [pickerType]="'calendar'" #pickDateRange></owl-date-time>
                      <label>{{singleFilter.label}}</label>
                      <i class="icon icon-event"></i>
                    </div>
                  </div>
              </ng-container>
  
              <ng-container *ngSwitchCase="'time'">
                  <div class="form-group m-b20">
                    <div class="fields">
                      <input type="text" [name]="singleFilter.name" [(ngModel)]="singleFilter.value" [owlDateTime]="pickTimeRange" [owlDateTimeTrigger]="pickTimeRange" placeholder="00:00 ~ 01:00" [selectMode]="'range'" (ngModelChange)="updateDatePicker($event, singleFilter)" placeholder="Enter here" readonly>
                      <owl-date-time [pickerType]="'timer'" #pickTimeRange></owl-date-time>
                      <label>{{singleFilter.label}}</label>
                      <i class="icon icon-ic-clock"></i>
                    </div>
                  </div>
              </ng-container>
  
              <ng-container *ngSwitchCase="'number'">
                  <div class="form-group">
                    <div class="fields">
                      <input type="number" [name]="singleFilter.name" [(ngModel)]="singleFilter.value" placeholder="Enter here" min="0">
                      <label>{{singleFilter.label}}</label>
                    </div>
                  </div>
              </ng-container>
  
              <ng-container *ngSwitchCase="'activeswitch'">
                  <div class="form-group">
                      <div class="fields focused">
                        <div class="cs-select">
                          <select [name]="singleFilter.name" class="cs-select-effect selectpicker" [(ngModel)]="singleFilter.value" (ngModelChange)="updateStatus($event, singleFilter)">
                            <option value="">Select Status</option>
                            <option type="number" [ngValue]="1">Active</option>
                            <option type="number" [ngValue]="0">Inactive</option>
                          </select>
                          <label>{{singleFilter.label}}</label>
                        </div>
                      </div>
                  </div>
              </ng-container>
  
  
              <ng-container *ngSwitchCase="'dropdown'">
                <div class="form-group">
                    <div class="fields focused">
                      <div class="cs-select">
                        <select [name]="singleFilter.name" class="cs-select-effect selectpicker" [(ngModel)]="singleFilter.value" (ngModelChange)="updateStatus($event, singleFilter)">
                          <option value="">Select Status</option>
                          <option type="number" *ngFor="let list of singleFilter.option" [ngValue]="list.id">{{list.name}}</option>
                        </select>
                        <label>{{singleFilter.label}}</label>
                      </div>
                    </div>
                </div>
            </ng-container>
  
            <ng-container *ngSwitchCase="'dropdownWithName'">
              <div class="form-group">
                  <div class="fields focused">
                    <div class="cs-select">
                      <select [name]="singleFilter.name" class="cs-select-effect selectpicker" [(ngModel)]="singleFilter.value" (ngModelChange)="updateStatus($event, singleFilter)">
                        <option value="">Select Status</option>
                        <option type="text" *ngFor="let list of singleFilter.option" [ngValue]="list.name">{{list.name}}</option>
                      </select>
                      <label>{{singleFilter.label}}</label>
                    </div>
                  </div>
              </div>
          </ng-container>
  
  
  
   </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="text-right btn-group-c btn-sticky">
        <button type="reset"  class="secondary-btn text-center" (click)="resetFilters()">Reset</button>
        <button type="submit" class="primary-btn ml-3">Apply</button>
      </div>
    </form>
  </div>
  