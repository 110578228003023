import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services';
import { LoggerService } from './logger.service';
import * as moment from 'moment';
import { UserDataActions } from '../redux/actions';
import { Store } from '@ngrx/store';
import { API_URL } from './api-end-points';
import { HttpRequestService } from './http-request.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private store: Store,
    private httpService: HttpRequestService,
    private loggerService: LoggerService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // add authorization header with jwt token if available
    const loginUser = JSON.parse(localStorage.getItem('userDetails'));
    const utcOffSet = moment().utcOffset();

    // skip those requests where no need of authentication pass "skipheader"
    if (request.headers.has('skipHeaders')) {
      // const headers = request.headers.delete('skipHeaders');
      // request = request.clone({headers});
    } else {
      if (loginUser && loginUser.token) {
        request = request.clone({
          setHeaders: {
            // userid: loginUser.userid,
            // contentlanguage: 'en',
            // utcoffset: `${utcOffSet}`,
            // devicetype: 'WEB',
            Authorization: `Bearer ${loginUser.token}`
          }
        });
      } else {
        const guestToken = localStorage.getItem('_guestData');

        request = request.clone({
          setHeaders: {
            // userid: loginUser.userid,
            // contentlanguage: 'en',
            // utcoffset: `${utcOffSet}`,
            // devicetype: 'WEB',
            // Authorization: `Bearer ${guestToken}`
          }
        });
      }
      // this.logger.log(request);
    }

    return next.handle(request).pipe(
      tap(
        event => {
          // this.logger.log(event);
        },
        error => {
          this.loggerService.log(error);
          const errorJson: any = error.error;

          if (errorJson.statusCode === 401) {
            this.router.navigate(['/']);
            this.logout();
            localStorage.clear();
            this.setCurrentUserData(null);
            this.getGuestToken();
            localStorage.clear();
            this.toastr.error(errorJson.message, 'Logged Out!', { timeOut: 3000 });
            // }
          }
        }
      )
    );
  }


  setCurrentUserData(userData) {
    this.store.dispatch(new UserDataActions.saveDetail(userData));
  }

  getGuestToken() {
    if (localStorage.getItem('_guestData')) {
      return localStorage.getItem('_guestData');
    } else {
      return this.generateGuestToken();
    }
  }

  generateGuestToken() {
    this.httpService.getData(API_URL._GET_GUEST_TOKEN, {}).subscribe((resp: any) => {
      localStorage.setItem('_guestData', resp.data.token);
      return resp.data.token;
    });
  }

  logout() {
    // this.httpService.postData(API_URL._POST_LOGOUT, {}).subscribe(resp => {
    //   localStorage.clear();
    //   this.setCurrentUserData(null);
    //   this.getGuestToken();
    //   return this.router.navigate(['/']);
    // }, error => {
    //   this.toastr.error(error.error.message);
    //   localStorage.clear();
    //   this.setCurrentUserData(null);
    // });
  }

}
