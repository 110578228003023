<div class="listing-columns">
    <div class="sidepanel-height">
      <div class="column_title">
        <h4>Column Options</h4>
        <p>Add or remove columns. To change the column order, drag and drop a field.</p>
        <!--, or use ⌘+Up or ⌘+Down-->
        <span class="slide-close icon-arrow-long" (click)="close()"></span>
      </div>
  
      <h6 class="columns-underline">Columns</h6>
      <div class="column-form" cdkDropList cdkDragBoundary (cdkDropListDropped)="drop($event)">
        <div cdkDrag class="column-groum" *ngFor="let singleColumn of allColumns; index as i">
        <span class="column-drag_icon" cdkDragHandle>
          <img src="assets/images/dots.png" alt=""/>
        </span>
          <label>{{singleColumn.label}}</label>
          <a class="input_close" href="javascript:void(0);" (click)="removeColumn(i)"></a>
        </div>
  
        <!--<a href="javascript:void(0);" class="add-btn primary-btn w-bg" (click)="addNewColumn()">Add column</a>-->
      </div>
  
    </div>
    <div class="text-right btn-group-c btn-sticky">
      <a href="javascript:void(0);" class="secondary-btn" (click)="close()">Cancel</a>
      <a href="javascript:void(0);" class="primary-btn" (click)="saveColumns()">Save</a>
    </div>
  
  
  </div>
  