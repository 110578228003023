import {LOCATION_DATA_CONSTANTS, LocationDataActionTypes} from '../actions';


const initialState = { };

export function LocationDetailReducer(state = initialState, action: LocationDataActionTypes) {
  switch (action.type) {
    case LOCATION_DATA_CONSTANTS.SAVE_LOCATION_DETAIL:
      return {
        ...state,
        ...action.payload
      };

    case LOCATION_DATA_CONSTANTS.RESET_LOCATION_DETAIL:
      return initialState;

    default:
      return state;
  }
}
