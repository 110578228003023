import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private onSearchOutlet = new Subject<any>();
  private category = new Subject<any>();
  private product = new Subject<any>();
  private customer = new Subject<any>();
  private groupOfCustomer = new Subject<any>();
  private buyCustomerProduct = new Subject<any>();
  private getCustomerProduct = new Subject<any>();
  private delete = new Subject<any>();
  private markStatusAsReady = new Subject<any>();
  private leadUpdate = new Subject<any>();


  constructor() { }

  searchAreaOutlet(): Observable<any> {
    return this.onSearchOutlet.asObservable();
  }

  onSearchAreaOutlet(data){
    this.onSearchOutlet.next(data);
  }

  Category(): Observable<any> {
    return this.category.asObservable();
  }

  onCategory(data){
    this.category.next(data);
  }

  Product(): Observable<any> {
    return this.product.asObservable();
  }

  onProduct(data){
    this.product.next(data);
  }

  Customer(): Observable<any> {
    return this.customer.asObservable();
  }

  onCustomer(data){
    this.customer.next(data);
  }

  groupCustomer(): Observable<any> {
    return this.groupOfCustomer.asObservable();
  }

  onGroupCustomer(data){
    this.groupOfCustomer.next(data);
  }

  buyCustomerProducts(): Observable<any> {
    return this.buyCustomerProduct.asObservable();
  }

  onBuyCustomerProducts(data){
    this.buyCustomerProduct.next(data);
  }

  getCustomerProducts(): Observable<any> {
    return this.getCustomerProduct.asObservable();
  }

  onGetCustomerProducts(data){
    this.getCustomerProduct.next(data);
  }

  Delete(): Observable<any> {
    return this.delete.asObservable();
  }

  onDelete(data){
    this.delete.next(data);
  }

  MarkStatus(): Observable<any> {
    return this.markStatusAsReady.asObservable();
  }

  onMarkStatus(data){
    this.markStatusAsReady.next(data);
  }

  updateLead(): Observable<any> {
    return this.leadUpdate.asObservable();
  }

  onUpdateLead(data){
    this.leadUpdate.next(data);
  }

}



