import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {ToastrService} from 'ngx-toastr';
import {LocationDataActions} from '../redux/actions';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private store: Store<any>,
    // private httpService: HttpRequestService,
    private toastr: ToastrService,
  ) {
    this.region = this.getRegion();
  }

  apiRefreshSubject: Subject<any> = new Subject<any>();
  apiRefreshObservable = this.apiRefreshSubject.asObservable();

  region: any = {};
  regionSubject: Subject<any> = new Subject<any>();
  regionObservable = this.regionSubject.asObservable();

  productSearchSubject: Subject<any> = new Subject<any>();
  productSearchObservable = this.productSearchSubject.asObservable();

  private lat: any;
  private lon: any;

  // searchedLocation: any;

  refreshApi(API) {
    this.apiRefreshSubject.next(API);
  }

  productSearchChange(keyWord) {
    this.productSearchSubject.next(keyWord);
  }

  public setRegion(data) {
    this.region = data;
    this.store.dispatch(new LocationDataActions.saveDetail(data));
  }

  public getRegion() {
    if (localStorage.getItem('_region')) {
      this.region = JSON.parse(localStorage.getItem('_region'));
      return this.region;
    } else {
      return null;
    }
  }

  /*getShopData() {
    const regionData = Object.assign({}, this.getRegion());
    const searchParams: any = {userLongitude: regionData.longitude, userLatitude: regionData.latitude};
    this.httpService.getData(API_URL._GET_IDS_FROM_REGION, searchParams).subscribe((resp: any) => {
      if (resp.data.isServiceAvailable === 1) {
        const service = resp.data;
        this.store.dispatch(new ShopDataActions.saveDetail(service));
      } else {
        // this.router.navigate([`/`]);
        this.store.dispatch(new ShopDataActions.saveDetail({groceryId: null, shopId: null,}));
        this.toastr.error(resp.data.serviceAvailableMessage, ``, {timeOut: 5000});
      }
    });
  }*/


}


