<!-- The Modal -->
<div>
        <!-- Modal Header -->
        <div class="modal-header">
          <h5 class="modal-title">Transactions</h5>
          <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
        </div>
  
        <!-- <app-loader *ngIf="isLoader"></app-loader> -->

        <!-- Modal body -->
        <div class="modal-body" *ngIf="walletData.length > 0">
          <div class="transactionBody">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Reason</th>
                  <th>Amount</th>
                  <th>TYPE</th>
                </tr>
              </thead>
  
              <tbody>
                <tr *ngFor="let wallet of walletData">
                    <td>{{ wallet.id }}</td>
                    <td>{{ wallet.date }} </td>
                    <td>Paid for <span class="text-blue"> {{ wallet.reason }} </span></td>
                    <td>{{currency}}  {{ wallet.amount }} </td>
                    <td> {{ wallet.type }} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  </div>
