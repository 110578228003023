import {BRAND_DATA_CONSTANTS, BrandDataActionTypes} from '../actions';


const initialState = {id: '', image: '', brand_name: ''};

export function BrandDataReducer(state = initialState, action: BrandDataActionTypes) {
  switch (action.type) {
    case BRAND_DATA_CONSTANTS.SAVE_BRAND_DATA:
      return {
        ...state,
        ...action.payload
      };

    case BRAND_DATA_CONSTANTS.RESET_BRAND_DETAIL:
      return initialState;

    default:
      return state;
  }
}
