import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { API_URL, HttpRequestService } from 'src/app/core';
import { SearchService } from 'src/app/shared/search.service';
import { FormValidator } from 'src/app/validators';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-lead-request-popup',
  templateUrl: './lead-request-popup.component.html',
  styleUrls: ['./lead-request-popup.component.scss']
})
export class LeadRequestPopupComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private httpRequestService: HttpRequestService,
    private popupService: BsModalService,
    private toastr: ToastrService,
    private searchService: SearchService,
  ) { }

  formGrouptoken: FormGroup = this.formBuilder.group({
    link: new FormControl('', []),
  });

  formGroupPlan: FormGroup = this.formBuilder.group({
    plan: new FormControl('', []),
  });

  formGroupChangeStatus: FormGroup = this.formBuilder.group({
    status: new FormControl('', [Validators.compose([Validators.required])]),
    notes: new FormControl('', [Validators.compose([Validators.required])])
  });

  formGroup: FormGroup = this.formBuilder.group({
    status: new FormControl('', [Validators.compose([Validators.required])]),
    notes: new FormControl('', Validators.compose([Validators.required])),
    price: new FormControl('', []),
  });

  isToken: boolean = false;
  changeStatus: boolean = false;
  loading = { api: false, form: false };
  allPlans: any[] = [];
  selectedPrice;
  selectedDurationType;
  selectedPlanId;
  selectedPlan;
  link;
  params;
  currency;
  paidStatus: any[] = [
    {
      id: '',
      name: 'Select Status'
    },
    {
      id: 'PAID_BY_ADMIN',
      name: 'Paid'
    },
  ];

  status: any[] = [
    {
      id: 'CONTACTED',
      name: 'Contacted'
    },
    {
      id: 'CONFIRMED',
      name: 'Confirmed'
    },
    {
      id: 'DECLINED',
      name: 'Declined',
    }
  ];

  statusWithoutContacted: any[] = [
    {
      id: 'INBARGAIN',
      name: 'In Bargain'
    },
    {
      id: 'CONFIRMED',
      name: 'Confirmed'
    },
    {
      id: 'DECLINED',
      name: 'Declined'
    }
  ];

  ngOnInit(): void {
    
    if(!this.params?.isStatic){
      this.getLeadRequests();
    }

    const settings = JSON.parse(localStorage.getItem('settings'));
    if(settings){
      this.currency = settings?.code;
    }

    if (this.params?.status === 'Contacted' || this.params?.status === 'In Bargain' ) {
      this.status = this.statusWithoutContacted;
    } else {
      this.status = this.status;
    }
    if (this.params?.payment?.link) {
      this.isToken = true;
      this.formGrouptoken.get('link').patchValue(this.params.payment.link);
    }

    this.getPlans();
  }

  getLeadRequests() {
    this.loading.api = true;
    const url = API_URL._GET_ALL_LEAD_REQUEST;
    this.httpRequestService.getData(url, {id: this.params?.actionId}).subscribe(
      (resp: any) => {
        this.params = resp?.data;
        this.loading.api = false
      }, (error) => {
        this.loading.api = false;
      }
    )
  }

  onlyNumbers(event) {
    FormValidator.onlyNumbers(event);
  }

  /* To copy Text from Textbox */
  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.toastr.info('Link Copied');
  }

  getPlans() {
    this.loading.api = true;
    const url = API_URL._GET_ALL_PLANS;
    this.httpRequestService.getData(url, {}).subscribe(
      (resp: any) => {
        this.allPlans = resp.data?.plans;
        this.selectedPlan = this.allPlans.find(plan=> plan?._id === this.params?.plan?._id);
        this.formGroupPlan.get('plan').patchValue(this.selectedPlan?._id);
        this.loading.api = false;
      }, (error) => {
        this.loading.api = false;
      }
    )
  }

  // on plan select
  onSelectPlan() {
    const selectedPlanId = this.formGroupPlan.get('plan').value;
    const selectedPlan = this.allPlans.find(_plan => _plan._id == selectedPlanId);
    this.selectedDurationType = selectedPlan.durationType;
    this.selectedPrice = selectedPlan.price;
    this.selectedPlanId = selectedPlanId;
  }

  changeLeadStatus() {
    if (this.formGroup.get('status').value === 'Confirmed') {
      this.formGroup.get('price').setValidators([Validators.required]);
      this.formGroup.get('price').updateValueAndValidity({ onlySelf: true });
    } else {
      this.formGroup.get('price').clearValidators();
      this.formGroup.get('price').setValidators([]);
      this.formGroup.get('price').updateValueAndValidity({ onlySelf: true });
    }
  }

  changePlanStatus(event){
    this.formGroupChangeStatus.get('status').patchValue('PAID_BY_ADMIN');
  }

  onFocusOut(_event) {
    _event.target.value = parseInt(_event.target.value);
  }

  submitStatus(){
    if (this.formGroupChangeStatus.valid) {
      this.loading.form = true;
      const url = API_URL._POST_LEAD_UPDATE;
      let payload = this.formGroupChangeStatus.value;
      payload.id = this.params._id;
      this.httpRequestService.postData(url, payload).subscribe(
        (resp) => {
          this.loading.form = false;
          this.toastr.success(`Status updated successfully!`, `Success`, { timeOut: 1500 });
          this.searchService.onUpdateLead(resp.data);
          this.popupService.hide();
        }, (error) => {
          this.loading.form = false;
        });
    } else {
      Object.keys(this.formGroupChangeStatus.controls).forEach(key => {
        this.formGroupChangeStatus.get(key).markAsTouched({ onlySelf: true });
      });
    }
  }

  submit() {
    if (this.formGroup.valid) {
      this.loading.form = true;
      const url = API_URL._POST_LEAD_UPDATE;
      let payload = this.formGroup.value;
      payload.id = this.params._id;
      payload.price = +payload.price;
      // const status = this.formGroup.get('status').value;
      // payload.status = status.toUpperCase();
      payload.plan = this.selectedPlanId;

      this.httpRequestService.postData(url, payload).subscribe(
        (resp) => {
          this.loading.form = false;
          this.toastr.success(`Lead updated successfully!`, `Success`, { timeOut: 1500 });
          this.searchService.onUpdateLead(resp.data);
          this.popupService.hide();
        }, (error) => {
          this.loading.form = false;
        });
    } else {
      Object.keys(this.formGroup.controls).forEach(key => {
        this.formGroup.get(key).markAsTouched({ onlySelf: true });
      });
    }

  }

  close() {
    this.popupService.hide();
  }

}