import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ViewBrandsComponent} from './view-brands.component';
import { RouterModule, Routes } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule, SelectPickerModule } from '@app/directives';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
    { path: '', component: ViewBrandsComponent, 
}];

@NgModule({
  declarations: [
    ViewBrandsComponent,
  ],
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule,
    SelectPickerModule,
    NgbTooltipModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    ViewBrandsComponent
  ],
})
export class ViewBrandsModule {
}
