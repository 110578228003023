import {Action} from '@ngrx/store';

export const INSTA_STEP_DATA_CONSTANTS = {
  SAVE_INSTA_STEP_DATA: '[Insta Detail] Save',
  RESET_INSTA_STEP_DETAIL: '[Insta Detail] Reset',
};


class SaveInstaStepData implements Action {
  readonly type = INSTA_STEP_DATA_CONSTANTS.SAVE_INSTA_STEP_DATA;
  constructor(public payload: any) {
  }
}

class ResetInstaStepData implements Action {
  readonly type = INSTA_STEP_DATA_CONSTANTS.RESET_INSTA_STEP_DETAIL;
}

export const InstaStepDataActions = {
  saveDetail: SaveInstaStepData,
  restDetails: ResetInstaStepData
};




export const InstaStepDataSelectors = {
  detail: (state: any) => state._instaStep,
};

export type InstaStepDataActionTypes = SaveInstaStepData;
