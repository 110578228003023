import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingTableComponent } from './listing-table.component';
import { NgxPaginationModule } from 'ngx-pagination';
// import { ChartsSliderModule } from '../charts-slider/charts-slider.module';
import { FormsModule } from '@angular/forms';
import { LoaderModule } from '../loader/loader.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
// import {TruncatePipeModule} from '@app/_pipe/truncate-pipe/truncate-pipe.module';

@NgModule({
  declarations: [
    ListingTableComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    NgbModule,
    LoaderModule,
    DragDropModule,
    // ChartsSliderModule,
    NgxPaginationModule,
    // TruncatePipeModule
  ],
  exports: [
    ListingTableComponent,
  ]
})
export class ListingTableModule { }
