import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscriber } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { API_URL, HttpRequestService } from '../core';
import { select, Store } from '@ngrx/store';
import { UserDataActions, UserDataSelectors } from '../redux/actions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // user permissions
  private permissions: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public permissions$: Observable<any> = this.permissions.asObservable();

  constructor(
    private httpService: HttpRequestService,
    private router: Router,
    private store: Store
    // private loggerService: LoggerService,
  ) {
    this.getToken();

    /*this.subscriber.add(this.store.pipe(select(UserDataSelectors.detail)).subscribe(userDetail => {
      if (Object.keys(userDetail).length) {
        this.currentUser = userDetail;
        return this.currentUser;
      } else {
        this.currentUser = null;
        return false;
      }
    }));*/
  }

  private subscriber: Subscriber<any> = new Subscriber<any>();
  public currentUser;

  // private currentUserSubject: Subject<any> = new Subject<any>();
  // public currentUserObservable = this.currentUserSubject.asObservable();

  token;

  setCurrentUserData(userData) {
    this.store.dispatch(new UserDataActions.saveDetail(userData));
  }

  // // check if token exists
  // getToken() {
  //   let token = '';
  //   const user: any = this.getUser();
  //   if (user && user.token) {
  //     token = user.token;
  //   }
  //   this.token = token;
  //   return token;
  // }

  // check if token exists
  getToken() {
    let token = '';
    const tokens = JSON.parse(localStorage.getItem('userDetails'));
    // const user: any = this.getUser();
    if (tokens && tokens.token) {
      token = tokens.token;
      this.permissions.next(tokens.permissions);
    }
    this.token = token;
    return token;
  }

  get user() {
    const userDetails = localStorage.getItem('userDetails');
    return (userDetails) ? JSON.parse(userDetails) : null;
  }

  getUserRole() {
    let userRole = '';
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails) {
      userRole = userDetails.adminRole;
    }
    return userRole === 'agent' ? true : false;
  }

  hasToken() {
    const tokens = JSON.parse(localStorage.getItem('userDetails'));
    return (tokens) ? true : false;
  }

  // get token expiration date
  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);
    if (!decoded.exp) {
      return null;
    }
    const expiryDate = new Date(0);
    expiryDate.setUTCSeconds(decoded.exp);
    return expiryDate;
  }

  // check if token valid or expired
  isTokenExpired(): boolean {
    const token = this.getToken();
    if (!token) {
      return true;
    }
    const expiryDate = this.getTokenExpirationDate(token);
    return (expiryDate) ? !(expiryDate.valueOf() > new Date().valueOf()) : false;
  }


  // get user
  getUser(): any {
    let user: any = {};

  }

  // check if user is logged in or not
  isLoggedIn() {
    return !!this.getUser();
  }

  generateGuestToken() {
    this.httpService.getData(API_URL._GET_GUEST_TOKEN, {}).subscribe((resp: any) => {
      localStorage.setItem('_guestData', resp.data.token);
      return resp.data.token;
    });
  }

  getGuestToken() {
    if (localStorage.getItem('_guestData')) {
      return localStorage.getItem('_guestData');
    } else {
      return this.generateGuestToken();
    }
  }

  // has access
  hasAccess(perms) {
    let hasPerm = false;
    let permissions = this.user?.permissions;
    console.log('permissions',permissions)
    if(permissions && permissions.length) {
      if (permissions.includes('all')) {
        hasPerm = true;
      }
      else {
        permissions.forEach(_perm => {
          if(!hasPerm && perms.includes(_perm)) {
            hasPerm = true;
          }
        })
      }
    }
    return hasPerm;
  }

  logout() {
    const userDetail = JSON.parse(localStorage.getItem('userDetails'));
    this.httpService.postData(API_URL._POST_LOGOUT, { token: userDetail.token }).subscribe(resp => {
      localStorage.clear();
      this.setCurrentUserData(null);
      // this.getGuestToken();
      return this.router.navigate(['/login']);
    }, error => {
      localStorage.clear();
      this.setCurrentUserData(null);
    });
  }

}
