<!-- The Modal -->
<div>
    <!-- Modal Header -->
    <div class="modal-header">
        <h5 class="modal-title">Change Password</h5>
        <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
    </div>

    <!-- Modal body -->
    <div class="modal-body">
        <form class="fundsBody" [formGroup]="formGroup">
            <div class="form-in">
                <label>Old Password</label>
                <div class="showpass">
                    <input type="text" formControlName="oldPassword" [type]="show ? 'text' : 'password'" placeholder="Eg. Old Password" />
                    <a href="javascript:;" class="toggle-password password-view" (click)="showHidePassword()">{{show ?
                        'Hide' : 'Show'}}</a>
                </div>
                <div *ngIf="formGroup.get('oldPassword').invalid && formGroup.get('oldPassword').touched">
                    <span class="error" *ngIf="formGroup.get('oldPassword').hasError('required')">Old Password is
                        required</span>
                </div>
            </div>

            <div class="form-in">
                <label>New Password</label>
                <div class="showpass">
                    <input type="text" formControlName="password" [type]="showNewPassword ? 'text' : 'password'" placeholder="Eg. New Password" />
                    <a href="javascript:;" class="toggle-password password-view" (click)="showHideNewPassword()">{{showNewPassword ?
                        'Hide' : 'Show'}}</a>
                </div>
                <div class="formMessage" *ngIf="formGroup.get('password').invalid && formGroup.get('password').touched">
                    <span class="error" *ngIf="formGroup.get('password').hasError('required')">New Password is
                        required</span>
                    <span class="error" *ngIf="formGroup.get('password').errors?.minlength">Password must be at least 8
                            characters</span>
                    <span class="error" *ngIf="formGroup.get('password').errors?.invalidPass">Password must have [a-z],[0-9] & 1
                            Capital, 1 Special character.</span>
                    <span class="error" *ngIf="formGroup.get('password').errors?.maxlength">Password length should be less than
                            100</span>
                </div>
            </div>

        </form>
    </div>

    <!-- Modal footer -->
    <div class="modal-footer border-0">
        <button type="button" class="btn-white sm" (click)="close()">Cancel</button>
        <button type="button" (click)="updatePassword()" [ngClass]="{'loader': loading.form}" class="btn sm">Update
            Password</button>
    </div>

</div>