import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-marketing-requests-detail-popup',
  templateUrl: './marketing-requests-detail-popup.component.html',
  styleUrls: ['./marketing-requests-detail-popup.component.scss']
})
export class MarketingRequestsDetailPopupComponent implements OnInit {
  constructor(
    private popupService: BsModalService,
  ) { }

  params;

  ngOnInit(): void {
  }

  close() {
    this.popupService.hide();
  }
}
