<!-- The Modal -->


  
        <!-- Modal Header -->
        <div class="modal-header">
          <h5 class="modal-title">Refund #{{ params.record.id }}, Order amount: {{currency}} {{ params.record.amount }}</h5>
          <button type="button" class="close" (click)="close()">&times;</button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
          <div class="ordeRefundBody">
            <form [formGroup]="formGroup">
              <div class="form-in">
                <label>Amount</label>
                <input type="text" formControlName="amount" (keypress)="onlyNumbers($event)" placeholder="Eg. 100"/>
                <div *ngIf="formGroup.get('amount').invalid && formGroup.get('amount').touched">
                    <span class="error" *ngIf="formGroup.get('amount').hasError('required')">Amount is required</span>
                </div>
              </div>
  
              <div class="form-in">
                <label>Transfer Type</label>
                <div class="select-div">
                    <select-picker formControlName="transferType" firstOption="Select any type" [options]="allTransferTypes" [search]="true"></select-picker>
                    <div *ngIf="formGroup.get('transferType').invalid && formGroup.get('transferType').touched">
                      <span class="error" *ngIf="formGroup.get('transferType').hasError('required')">Transfer Type is required</span>
                  </div>
                </div>
              </div>
  
              <div class="form-in">
                <label>Refund Reason</label>
                <div class="select-div">
                    <select-picker formControlName="refundReason" firstOption="Select any reason" [options]="allRefundReason" [search]="true"></select-picker>
                    <div *ngIf="formGroup.get('refundReason').invalid && formGroup.get('refundReason').touched">
                      <span class="error" *ngIf="formGroup.get('refundReason').hasError('required')">Refund Reason is required</span>
                  </div>
                </div>
              </div>
  
              <div class="form-in">
                <label>Other</label>
                <textarea formControlName="other" rows="4"></textarea>
                <!-- <div *ngIf="formGroup.get('other').invalid && formGroup.get('other').touched">
                    <span class="error" *ngIf="formGroup.get('other').hasError('required')">Name is required</span>
                </div> -->
              </div>
  
              <div class="form-in btn-grp">
                <a href="javascript: //" class="btn-white sm" (click)="close()">Cancel</a>
                <a href="javascript: //" class="btn sm" (click)="submit()">Submit details</a>
              </div>
            </form>
          </div>
        </div>
  
