import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormValidator } from '@app/validators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { API_URL, HttpRequestService } from 'src/app/core';
import { HelperService } from 'src/app/services';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-merchant-detail-popup',
  templateUrl: './merchant-detail-popup.component.html',
  styleUrls: ['./merchant-detail-popup.component.scss'],
})
export class MerchantDetailPopupComponent implements OnInit {
  constructor(
    private popupService: BsModalService,
    private formBuilder: FormBuilder,
    private httpRequestService: HttpRequestService,
    private toastr: ToastrService,
    private helperService: HelperService
  ) {}

  status;
  params;
  loading = { api: true, form: false };
  remainigPlanDays;
  @ViewChild('NgbdDatepicker') d: NgbDateStruct;

  formGroup: FormGroup = this.formBuilder.group({
    expiryDate: new FormControl('', [Validators.required]),
  });

  ngOnInit(): void {
    if (this.params && this.params.loadId) {
      this.openMerchantDetail(this.params.loadId);
    } else {
      this.loading.api = false;
    }
    console.log(this.params, 'params');

    this.calculateDay();
  }

  calculateDay() {
    const userData = JSON.parse(localStorage.getItem('userDetails'));
    let currentDate = new Date();
    let expiryDate = new Date(userData?.subscription?.expiryDate);
    if (expiryDate) {
      let remainigPlanTime = expiryDate?.getTime() - currentDate?.getTime();
      if (remainigPlanTime)
        this.remainigPlanDays = Math.floor(
          remainigPlanTime / (1000 * 3600 * 24)
        );
    }
  }

  get isActive() {
    return;
  }

  // open merchant details
  openMerchantDetail(vendorId) {
    const url = API_URL._GET_ALL_MERCHANTS;
    this.httpRequestService
      .getData(url, { id: vendorId })
      .subscribe((resp: any) => {
        let record = resp.data;
        this.params = record;
        this.loading.api = false;
      });
  }

  changeStatus(record) {
    this.status = record ? true : false;
    const payload = {
      id: this.params._id,
      type: 'vendor',
      isActive: this.status,
    };
    const url = API_URL._POST_UPDATE_STATUS;
    this.httpRequestService.postData(url, payload).subscribe(
      (resp) => {
        this.helperService.refreshApi(API_URL._GET_ALL_MERCHANTS);
        this.toastr.success('Status Updated', 'Success');
        this.popupService.hide();
      },
      (error) => {}
    );
  }

  onlyNumbers(event) {
    FormValidator.onlyNumbers(event);
  }

  onFocusOut(_event) {
    _event.target.value = parseInt(_event.target.value);
  }

  submit() {
    console.log(this.formGroup.value, 'this.formGroup.valid)');
    var selectedDate = this.formGroup.get('expiryDate').value;
    var expiryDate = new Date(this.params?.subscription?.baseExpiryDate);
    console.log(selectedDate, 'selectedDate');
    console.log(expiryDate, 'ToDate');
    console.log(new Date(selectedDate).getTime() , expiryDate.getTime(),'pppppp')
    if (this.formGroup.get('expiryDate').value) {
      if (new Date(selectedDate).getTime() > expiryDate.getTime()) {
        this.formGroup.get('expiryDate').setValue(selectedDate);
        // this.formGroup.controls['expiryDate'].updateValueAndValidity();
      } else {
        this.formGroup.get('expiryDate').setValue(selectedDate);
        this.formGroup.controls['expiryDate'].setErrors({ incorrect: true });
      }
    }

    console.log(this.formGroup.controls);

    if (this.formGroup.valid) {
      const url = API_URL._EXTEND_VENDOR_PLAN;
      let payload = this.formGroup.value;
      payload.id = this.params._id;

      this.httpRequestService.postData(url, payload).subscribe(
        (resp) => {
          // this.loading.form = false;
          this.toastr.success(`Plan extended successfully!`, `Success`, {
            timeOut: 1500,
          });
          this.helperService.refreshApi(API_URL._GET_ALL_MERCHANTS);
          // this.searchService.onUpdateLead(resp.data);
          this.popupService.hide();
        },
        (error) => {
          // this.loading.form = false;
        }
      );
    } else {
      Object.keys(this.formGroup.controls).forEach((key) => {
        this.formGroup.get(key).markAsTouched({ onlySelf: true });
      });
    }
  }

  close() {
    this.popupService.hide();
  }
}
