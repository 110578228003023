import { from } from 'rxjs';

export * from './signup-popup/signup-popup.component';
export * from './one-time-password-popup/one-time-password-popup.component';
export * from './location-picker-popup/location-picker-popup.component';
export * from './add-edit-address-popup/add-edit-address-popup.component';
export * from './edit-phone-number-popup/edit-phone-number-popup.component';
export * from './order-rate-popup/order-rate-popup.component';
export * from './view-transaction-popup/view-transaction-popup.component';
export * from './delete-popup/delete-popup.component';
export * from './mark-status-ready-popup/mark-status-ready-popup.component';
export * from './lead-request-popup/lead-request-popup.component';
export * from './merchant-detail-popup/merchant-detail-popup.component';
export * from './merchant-invoice-popup/merchant-invoice-popup.component';
export * from './create-cuisine-popup/create-cuisine-popup.component';
export * from './manage-refund-popup/manage-refund-popup.component';
export * from './plan-change-popup/plan-change-popup.component';
export * from './assign-agent-popup/assign-agent-popup.component';
export * from './change-password-popup/change-password-popup.component';
export * from './contact-detail-popup/contact-detail-popup.component';
export * from './marketing-requests-detail-popup/marketing-requests-detail-popup.component';
export * from './view-brands/view-brands.component';
// export * from './';
