import { Component, OnInit } from '@angular/core';
import { API_URL, HttpRequestService } from '@app/core';
import { HelperService } from '@app/services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-brands',
  templateUrl: './view-brands.component.html',
  styleUrls: ['./view-brands.component.scss']
})
export class ViewBrandsComponent implements OnInit {

  constructor(
    private popupService: BsModalService,
    private httpRequestService: HttpRequestService,
    private helperService: HelperService,
    private toastrService: ToastrService
  ) {
  }

  params;
  allManagers: any[] = [];
  loading = { api: false, form: false };
  brands: any[] = [];

  ngOnInit(): void {
    
    if (this.params.id) {
      this.getDetails();
    }
  }

  getDetails() {
    this.loading.api=true;
    const url = API_URL._GET_BRAND_OUTLET_DETAILS;
    this.httpRequestService.getData(url, {vendor: this.params.id}).subscribe(
      (resp: any) => {
        this.loading.api = false;
        this.brands = resp.data;
      }, (error) => {
        this.loading.api=false;
      }
    )
  }

  close() {
    this.popupService.hide();
  }

}
