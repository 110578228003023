import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MerchantDetailPopupComponent} from './merchant-detail-popup.component';
import { RouterModule, Routes } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule, SelectPickerModule } from '@app/directives';
import { NgbModule, NgbTooltipModule, NgbDate, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
    { path: '', component: MerchantDetailPopupComponent, 
}];

@NgModule({
  declarations: [
    MerchantDetailPopupComponent,
  ],
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule,
    SelectPickerModule,
    NgbTooltipModule,
    NgbModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    MerchantDetailPopupComponent,
    NgbDatepicker
  ],
})
export class MerchantDetailPopupModule {
}
