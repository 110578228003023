export class Linker {
  /**
   * Event link
   * @param type
   * @param extra
   */
  public static eventLink(eventId, type, extra?) {
    let url = `/dashboard/event/${eventId}/${type}`;
    url = (extra)? `${url}/${extra}` : url;
    return url;
  }

  /**
   * vendor Event link
   * @param type
   * @param extra
   */
  public static vendorEventLink(eventId, type, extra?) {
    let url = `/vendor/event/${eventId}/${type}`;
    url = (extra)? `${url}/${extra}` : url;
    return url;
  }

  /**
   * Dashboard link
   * @param type   includes('brand')
   */
  public static dashLink(type) {
    if((type.includes('delivery-settings')) || (type.includes('pickup-settings')) || (type.includes('reservation-settings'))
    || (type.includes('bulk-order-settings'))){
      return `/${type}`;
    }else{
      return `/app/${type}`;
    }
  }

  /**
   * Dashboard Admin link
   */
  public static dashAdminLink(type) {
    if((type.includes('admin-delivery-settings')) || (type.includes('admin-pickup-settings')) || (type.includes('admin-reservation-settings'))
    || (type.includes('admin-bulk-order-settings'))){
      return `/${type}`;
    }else{
      return `/admin/${type}`;
    }
  }

  public static breadcrumbLink(type) {
    return `${type}`;
  }
  
   /**
   * Merchant Link
   * @param type
   */
  public static merchantLink(type) {
    return `/admin/${type}`;
  }

  /**
   * Admin Event link
   * @param type
   * @param extra
   */
  public static adminEventLink(eventId, type, extra?) {
    let url = `/admin/event/${eventId}/${type}`;
    url = (extra)? `${url}/${extra}` : url;
    return url;
  }
}
