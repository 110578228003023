import {AbstractControl} from '@angular/forms';
import {ConstantPool} from '@angular/compiler';

/*
 * Custom Form Validator
 */
export class FormValidator {

    // if field is empty
    static isEmpty(control: AbstractControl) {
      if (!control.value || (control.value && '' === control.value.trim())) {
        return {required: true};
      }
      return null;
    }
    
  static matchPassword(control: AbstractControl) {
    const password = control.get('password').value; // to get value in input tag
    const confirmPassword = control.get('confirmPassword').value; // to get value in input tag
    if (password != confirmPassword) {
      control.get('confirmPassword').setErrors({matchPassword: true});
    } else {
      return null;
    }
  }

  // if is valid email
  static validEmail(control: AbstractControl) {
    const EMAIL_REGEXP = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/i;
    if (control.value && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return {invalidEmail: true};
    }
    return null;
  }

  // only valid mobile number
  static validPhone(control: AbstractControl) {
    const PHONE_REGEXP = /(?!0{6})\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    if (control.value && !PHONE_REGEXP.test(control.value)) {
      return {invalidPhone: true};
    }
    return null;
  }

   // validate password
   static validPassword(control: AbstractControl) {
    const PASS_REGEXP = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=<>]).*$/;
    if (control.value && !PASS_REGEXP.test(control.value)) {
      return {invalidPass: true};
    }
    return null;
  }

  // only numbers allowed
  static onlyNumbers(event) {
    // skip hyphen (-)
    const pattern = /^-?(0|[1-9]\d*)?$/;
    const inputChar = String.fromCharCode(event.charCode);
    // skip if decimal
    if(event.keyCode == 46){
      return;
    }
    if (event.keyCode == 45 || !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

   // only letters
   static onlyLetters(event) {
    // skip hyphen (-)
    const pattern = /^[a-zA-Z -]+$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode == 45 || !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  
}
 