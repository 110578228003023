import { Component, OnInit } from '@angular/core';
import { API_URL, HttpRequestService } from '@app/core';
import { HelperService } from '@app/services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-assign-agent-popup',
  templateUrl: './assign-agent-popup.component.html',
  styleUrls: ['./assign-agent-popup.component.scss']
})
export class AssignAgentPopupComponent implements OnInit {
  params;
  allManagers: any[] = [];
  loading = { api: false, form: false };

  constructor(
    private popupService: BsModalService,
    private httpRequestService: HttpRequestService,
    private helperService: HelperService,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.getAllManagers();
  }

  getAllManagers() {
    this.loading.api = true;
    const url = API_URL._GET_ALL_MANAGERS;
    this.httpRequestService.getData(url, {}).subscribe((resp: any) => {
      this.allManagers = resp?.data?.users;
      this.loading.api = false;
    }, (error) => {
      this.loading.api = false;
    }
    );
  }

  assignUser(id) {
    this.loading.form = true;
    const url = API_URL._POST_ASSIGN_USER;
    this.httpRequestService.postData(url, { agentId: id, id: this.params._id }).subscribe((resp: any) => {
      this.loading.form = false;
      this.toastrService.success('Agent assigned successfully');
      this.helperService.refreshApi(API_URL._GET_ALL_LEAD_REQUEST);
      this.popupService.hide();
    }, (error) => {
      this.loading.form = false;
    }
    );
  }

  close() {
    this.popupService.hide();
  }

}
