import {Injectable} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {
  MerchantDetailPopupComponent,MerchantInvoicePopupComponent,
  CreateCuisinePopupComponent,LeadRequestPopupComponent,
  MarkStatusReadyPopupComponent,DeletePopupComponent,
  ViewTransactionPopupComponent,
  SignupPopup, OneTimePasswordPopup, LocationPickerPopup, EditPhoneNumberPopup, AddEditAddressPopup, OrderRatePopup
} from './';
import { AssignAgentPopupComponent } from './assign-agent-popup/assign-agent-popup.component';
import { ChangePasswordPopupComponent } from './change-password-popup/change-password-popup.component';
import { ContactDetailPopupComponent } from './contact-detail-popup/contact-detail-popup.component';
import { ManageRefundPopupComponent } from './manage-refund-popup/manage-refund-popup.component';
import { MarketingRequestsDetailPopupComponent } from './marketing-requests-detail-popup/marketing-requests-detail-popup.component';
import { PlanChangePopupComponent } from './plan-change-popup/plan-change-popup.component';
import { ViewBrandsComponent } from './view-brands/view-brands.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private popupService: BsModalService
  ) {
  }

  OutletModalRef: BsModalRef; // 1
  DisountModalRef: BsModalRef;
  AdminDisountModalRef: BsModalRef;
  SearchAreaModalRef: BsModalRef;
  ConnectingSalesModalRef: BsModalRef;
  ViewTransactionModalRef: BsModalRef;
  AddMoneyWalletModalRef: BsModalRef;
  OrderHistoryModalRef: BsModalRef;
  NewScheduledOrderModalRef: BsModalRef;
  deleteModalRef: BsModalRef;
  signUpModalRef: BsModalRef; // 2
  otpModalRef: BsModalRef; // 3
  editPhoneNumberRef: BsModalRef; // 4
  addressRef: BsModalRef; // 5
  locationRef: BsModalRef; // 6
  customRef: BsModalRef; // 7
  orderRateRef: BsModalRef; // 8
  couponRef: BsModalRef; // 10
  DeclineOrderModalRef: BsModalRef;
  AcceptOrderModalRef: BsModalRef;
  MarkStatusReadyModalRef: BsModalRef;
  copyStatusModalRef: BsModalRef;
  leadRequestModalRef: BsModalRef;
  MerchantDetailModalRef: BsModalRef;
  MerchantInvoiceModalRef: BsModalRef;
  CreateCuisineModalRef: BsModalRef;
  ManageRefundModalRef: BsModalRef;
  PlanChangeModalRef: BsModalRef;
  assignAgentModalRef: BsModalRef;
  changePasswordModalRef: BsModalRef;
  ContactDetailModalRef: BsModalRef;
  MarketingDetailModalRef: BsModalRef
  brandDetailModalRef: BsModalRef;

  DeletePopupComponent(params){
    const initialState = {
      params: {...params},
    };
    this.deleteModalRef = this.popupService.show(DeletePopupComponent,
      {id: 5, animated: true,class: 'yesNo', initialState: {...initialState}});
    this.deleteModalRef.content.ref = this.deleteModalRef;
  }

  ViewTransactionPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    
    this.ViewTransactionModalRef = this.popupService.show(ViewTransactionPopupComponent,
      {id: 7, animated: true,class: 'manageFunds modal-lg', initialState: {...initialState}});
    this.ViewTransactionModalRef.content.ref = this.ViewTransactionModalRef;
  }

  openSignUpPopup(params?): void {
    this.signUpModalRef = this.popupService.show(SignupPopup,
      {id: 11, animated: true, class: 'modal-dialog-centered'});
  }

  openLocationPickerPopup() {
    this.locationRef = this.popupService.show(LocationPickerPopup,
      {id: 12, animated: true});
  }

  openEdiPhoneNumberPopup(): void {
    this.editPhoneNumberRef = this.popupService.show(EditPhoneNumberPopup,
      {id: 14, animated: true, class: 'modal-dialog-centered'});
  }


  MarkStatusReadyPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    
    this.MarkStatusReadyModalRef = this.popupService.show(MarkStatusReadyPopupComponent,
      {id: 18, animated: true ,class: 'yesNo', initialState: {...initialState}});
    this.MarkStatusReadyModalRef.content.ref = this.MarkStatusReadyModalRef;
  }

  LeadRequestPopupComponent(params){
    const initialState = {
      params: {...params},
    };
    this.leadRequestModalRef = this.popupService.show(LeadRequestPopupComponent,
      {id: 20, animated: true,class: 'leadDetails w-900', initialState: {...initialState}});
    this.leadRequestModalRef.content.ref = this.leadRequestModalRef;
  }

  MerchantDetailPopupComponent(params){
    const initialState = {
      params: {...params},
    };
    this.MerchantDetailModalRef = this.popupService.show(MerchantDetailPopupComponent,
      {id: 21, animated: true,class: '', initialState: {...initialState}});
    this.MerchantDetailModalRef.content.ref = this.MerchantDetailModalRef;
  }

  MerchantInvoicesPopupComponent(params){
    const initialState = {
      params: {...params},
    };
    this.MerchantInvoiceModalRef = this.popupService.show(MerchantInvoicePopupComponent,
      {id: 22, animated: true,class: 'manageFunds modal-lg', initialState: {...initialState}});
    this.MerchantInvoiceModalRef.content.ref = this.MerchantInvoiceModalRef;
  }

  CreateNewCuisinePopupComponent(params?){
    const initialState = {
      params: {...params},
    };
    this.CreateCuisineModalRef = this.popupService.show(CreateCuisinePopupComponent,
      {id: 23, animated: true,class: 'manageFunds', initialState: {...initialState}});
    this.CreateCuisineModalRef.content.ref = this.CreateCuisineModalRef;
  }

  ManageRefundPopupComponent(params?){
    const initialState = {
      params: {...params},
    };
    this.ManageRefundModalRef = this.popupService.show(ManageRefundPopupComponent,
      {id: 24, animated: true,class: 'ordeRefund', initialState: {...initialState}});
    this.ManageRefundModalRef.content.ref = this.ManageRefundModalRef;
  }


  // PlanChangePopupComponent(params?){
    // const initialState = {
    //   params: {...params},
    // };
    // this.PlanChangeModalRef = this.popupService.show(PlanChangePopupComponent,
    //   {id: 25, animated: true,class: '', initialState: {...initialState}});
    // this.PlanChangeModalRef.content.ref = this.PlanChangeModalRef;

    
  // }

  PlanChangePopupComponent(): void {
    this.PlanChangeModalRef = this.popupService.show(PlanChangePopupComponent,
      {id: 25, animated: true, class: 'modal-dialog-centered'});
  }

  AssignAgentPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.assignAgentModalRef = this.popupService.show(AssignAgentPopupComponent,
      {id: 26, animated: true, class: 'manageFunds modal-lg', initialState: {...initialState}});
    this.assignAgentModalRef.content.ref = this.assignAgentModalRef;
  }

  changePasswordPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.changePasswordModalRef = this.popupService.show(ChangePasswordPopupComponent,
      {id: 27, animated: true, class: 'manageFunds', initialState: {...initialState}});
    this.changePasswordModalRef.content.ref = this.changePasswordModalRef;
  }

  ContactDetailPopupComponent(params){
    const initialState = {
      params: {...params},
    };
    this.ContactDetailModalRef = this.popupService.show(ContactDetailPopupComponent,
      {id: 28, animated: true,class: '', initialState: {...initialState}});
    this.ContactDetailModalRef.content.ref = this.ContactDetailModalRef;
  }

  MarketingDetailPopupComponent(params){
    const initialState = {
      params: {...params},
    };
    this.MarketingDetailModalRef = this.popupService.show(MarketingRequestsDetailPopupComponent,
      {id: 28, animated: true,class: 'modal-lg', initialState: {...initialState}});
    this.MarketingDetailModalRef.content.ref = this.MarketingDetailModalRef;
  }

  viewBrandDetailPopupComponent(params){
    const initialState = {
      params: {...params},
    };
    this.brandDetailModalRef = this.popupService.show(ViewBrandsComponent,
      {id: 28, animated: true,class: 'modal-lg', initialState: {...initialState}});
    this.brandDetailModalRef.content.ref = this.brandDetailModalRef;
  }

  // popup close
  close(type, msg = 'Cross click') {
    if(type === 'photo') {
      this.OutletModalRef.hide();
    }else if(type === 'search') {
      this.SearchAreaModalRef.hide();
    }
 
  }

}
