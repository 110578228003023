import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormValidator } from '../../validators/index';
import { HttpRequestService } from '../../core/http-request.service';
import { ToastrService } from 'ngx-toastr';
import { API_URL } from '../../core/api-end-points';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private httpRequestService: HttpRequestService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) { }

  formGroup: FormGroup = this.formBuilder.group({
    password: new FormControl('', [Validators.compose(
      [Validators.required, Validators.minLength(8), Validators.maxLength(100)])]),
    confirmPassword: new FormControl('', []),
    emailToken: new FormControl('', [])
  }, {
    validator: FormValidator.matchPassword // your validation method
  });

  loading = { form: false };
  show: boolean = false;
  token;

  ngOnInit(): void {
    this.route.queryParams.subscribe((param) => {
      this.token = param.token;
    });
  }

  submit() {
    if(!this.token) {
      this.toastr.error('Token is required');
      return;
    }
    if (this.formGroup.valid) {
      this.loading.form = true;
      this.formGroup.get('emailToken').patchValue(this.token);
      const param = this.formGroup.value;
      const url = API_URL._POST_RESET_PASSWORD;
      this.httpRequestService.postData(url, param).subscribe(
        (resp: any) => {
          if (resp.statusCode === 200) {
            this.toastr.success(`Password reset Successfully!`, `Success`, { timeOut: 1500 });
            this.router.navigate(['/login']);
          }
          this.loading.form = false;
        }, (error => {
          this.toastr.error(`Error Occured!`, `Error`, { timeOut: 1500 });
          this.loading.form = false;
        })
      )
      this.formGroup.reset();
    } else {
      Object.keys(this.formGroup.controls).forEach(key => {
        this.formGroup.get(key).markAsTouched({ onlySelf: true });
      });
    }
  }

  showHidePassword() {
    this.show = !this.show;
  }

}
