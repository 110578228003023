<div class="modalDetails">
  <div class="modal-header">
    <h5 class="modal-title">Request Details</h5>
    <button type="button" class="close" (click)="close()">&times;</button>
  </div>

  <div class="modal-body">
    <form class="modalDetails-in">
      <div class="borderBottom">
        <div>
          <p class="sm">Vendor Name:</p>
          <p class="text">{{ params?.vendorName }}</p>
        </div>
      </div>

      <div class="borderBottom">
        <div>
          <p class="sm">Vendor Email:</p>
          <p class="text">{{ params?.vendorEmail }}</p>
        </div>
      </div>

      <div class="borderBottom">
        <div>
          <p class="sm">Type:</p>
          <p class="text">{{ params?.typeName }}</p>
        </div>
      </div>

      <div class="borderBottom">
        <div>
          <p class="sm">Brand:</p>
          <p class="text">{{ params?.brand }}</p>
        </div>
      </div>

      <div class="borderBottom" *ngIf="params?.formData?.confirmOnSamePage">
        <div>
          <p class="sm">{{ params?.formData?.confirmOnSamePage?.name }}:</p>
          <!-- <ol>
            <li *ngFor="let page of params?.formData?.confirmOnSamePage?.value">{{ page?.label }}</li>
          </ol> -->
          <p *ngFor="let page of params?.formData?.confirmOnSamePage?.value" class="text">{{ page?.label }}</p>
        </div>
      </div>

      <div class="borderBottom">
        <div>
          <p class="sm">{{ params?.formData?.names?.name }}:</p>
          <p class="text">{{ params?.formData?.names?.value }}</p>
        </div>
      </div>

      <div class="borderBottom">
        <div>
          <p class="sm">{{ params?.formData?.email?.name }}:</p>
          <p class="text">{{ params?.formData?.email?.value }}</p>
        </div>
      </div>

      <div class="borderBottom">
        <div>
          <p class="sm">{{ params?.formData?.date?.name }}:</p>
          <p class="text">{{ params?.formData?.date?.value | date: 'mediumDate'}}</p>
        </div>
      </div>

      <div class="borderBottom">
        <div>
          <p class="sm">{{ params?.formData?.smsSentTime?.name }}:</p>
          <p class="text">{{ params?.formData?.smsSentTime?.value }}</p>
        </div>
      </div>

      <div class="borderBottom" *ngIf="params?.formData?.textSent?.name">
        <div>
          <p class="sm">{{ params?.formData?.textSent?.name }}:</p>
          <p class="text">{{ params?.formData?.textSent?.value }}</p>
        </div>
      </div>

      <div class="borderBottom" *ngIf="params?.formData?.smsSentTo?.name">
        <div>
          <p class="sm">{{ params?.formData?.smsSentTo?.name }}:</p>
          <p *ngFor="let sent of params?.formData?.smsSentTo?.value" class="text">{{ sent.label }}</p>
        </div>
      </div>

      <div class="borderBottom" *ngIf="params?.formData?.sendSmsYesNo?.name">
        <div>
          <p class="sm">{{ params?.formData?.sendSmsYesNo?.name }}:</p>
          <p class="text">{{ params?.formData?.sendSmsYesNo?.value }}</p>
        </div>
      </div>

      <div class="borderBottom" *ngIf="params?.formData?.information?.name">
        <div>
          <p class="sm">{{ params?.formData?.information?.name }}:</p>
          <p class="text">{{ params?.formData?.information?.value }}</p>
        </div>
      </div>

      <div class="borderBottom" *ngIf="params?.formData?.outlets.length">
        <div>
          <p class="sm">Outlets:</p>
          <p *ngFor="let outlet of params?.formData?.outlets"  class="text">{{ outlet?.name }}</p>
        </div>
      </div>

    </form>
  </div>
</div>