import {SHOP_DATA_CONSTANTS, ShopDataActionTypes} from '../actions';


const initialState = {};

export function ShopDataReducer(state = initialState, action: ShopDataActionTypes) {
  switch (action.type) {
    case SHOP_DATA_CONSTANTS.SAVE_SHOP_DATA:
      return {
        ...state,
        ...action.payload
      };

    case SHOP_DATA_CONSTANTS.RESET_SHOP_DETAIL:
      return initialState;

    default:
      return state;
  }
}
