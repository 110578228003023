import {Action} from '@ngrx/store';

export const WHATSAPP_STEP_DATA_CONSTANTS = {
  SAVE_WHATSAPP_STEP_DATA: '[whatsApp Detail] Save',
  RESET_WHATSAPP_STEP_DETAIL: '[whatsApp Detail] Reset',
};


class SaveWhatsAppStepData implements Action {
  readonly type = WHATSAPP_STEP_DATA_CONSTANTS.SAVE_WHATSAPP_STEP_DATA;
  constructor(public payload: any) {
  }
}

class ResetWhatsAppStepData implements Action {
  readonly type = WHATSAPP_STEP_DATA_CONSTANTS.RESET_WHATSAPP_STEP_DETAIL;
}

export const WhatsAppStepDataActions = {
  saveDetail: SaveWhatsAppStepData,
  restDetails: ResetWhatsAppStepData
};




export const WhatsAppStepDataSelectors = {
  detail: (state: any) => state._whatsAppStep,
};

export type WhatsAppStepDataActionTypes = SaveWhatsAppStepData;
