import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { API_URL, HttpRequestService } from 'src/app/core';
import { HelperService } from 'src/app/services';

@Component({
  selector: 'app-create-cuisine-popup',
  templateUrl: './create-cuisine-popup.component.html',
  styleUrls: ['./create-cuisine-popup.component.scss']
})
export class CreateCuisinePopupComponent implements OnInit {

  constructor(
    private popupService: BsModalService,
    private formBuilder: FormBuilder,
    private httpRequestService: HttpRequestService,
    private helperService: HelperService,
    private toastr: ToastrService
  ) { }

  params;
  loading = {api: false,form: false};
  formGroup: FormGroup = this.formBuilder.group({
    name:      new FormControl('', [Validators.compose([Validators.required])]),
  });

  ngOnInit() {
  if(this.params?.type === 1){
    this.formGroup.patchValue(this.params.record);
  }
  }

  submit(){
    if(this.formGroup.valid){
      this.loading.form = true;
      const url = API_URL._POST_CUISINS;
      const params = this.formGroup.value;
      if(this.params?.record?._id){
        params.id = this.params?.record._id;
      }
      this.httpRequestService.postData(url,params).subscribe(
        (resp: any)=>{
      this.loading.form = false;

          this.toastr.success('Cuisine saved successfully.');
          this.helperService.refreshApi(API_URL._GET_CUISINS);
          this.popupService.hide();
        },(error)=>{
          this.toastr.error(error.error.message, `Error`, { timeOut: 3000 });
        this.loading.form = false;

        }
      )
    }else{
      this.formGroup.markAllAsTouched();
    }
  }

  close(){
    this.popupService.hide();
  }

}
