<header>
<nav class="navbar navbar-expand-sm">
  <!-- Brand/logo -->
  <a class="navbar-brand" href="javascript: //">Delivery Papa</a>
  
  <!-- Links -->
  <!-- <ul class="navbar-nav single-li">
    <li>Don’t have an account?  
      <a class="nav-a" href="http://dp.illuminz.com">Signup now</a>
    </li>
  </ul> -->
</nav>
</header>

