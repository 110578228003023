import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-edit-address-popup',
  templateUrl: './add-edit-address-popup.component.html',
  styleUrls: ['./add-edit-address-popup.component.scss']
})

export class AddEditAddressPopup implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
