import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@app/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[hasAccess]'
})
export class HasAccessDirective {

  @Input() hasAccess: string[];
  stop$ = new Subject();
  isVisible = false;

  constructor(
    private auth: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef){

  }

  ngOnInit() {
    //  We subscribe to the perms$ to know the perms the user has
    this.auth.permissions$.pipe(
    	takeUntil(this.stop$)
    ).subscribe(perms => {
      // If he doesn't have any permission, we clear the viewContainerRef
      if (!perms) {
        this.viewContainerRef.clear();
      }
      // If the user has the perms needed to 
      // render this component we can add it
      if (this.hasAccess.find(_perm => perms.includes(_perm)) || perms.includes('all')) {
        // If it is already visible (which can happen if
        // his perms changed) we do not need to add it a second time
        if (!this.isVisible) {
          // We update the `isVisible` property and add the 
          // templateRef to the view using the 
          // 'createEmbeddedView' method of the viewContainerRef
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      } else {
        // If the user does not have the perms, 
        // we update the `isVisible` property and clear
        // the contents of the viewContainerRef
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    });
  }
  
  // Clear the subscription on destroy
  ngOnDestroy() {
    this.stop$.next();
  }
}
