import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CNavBarComponent} from './cnav-bar.component';


@NgModule({
  declarations: [
    CNavBarComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CNavBarComponent
  ]
})
export class CNavBarModule {
}
