<div class="modalDetails">
  <div class="modal-header">
    <h5 class="modal-title">{{ params?.name }}</h5>
    <button type="button" class="close" (click)="close()">&times;</button>
  </div>

  <app-loader *ngIf="loading.api"></app-loader>

  <div class="modal-body">
    <form class="modalDetails-in pb-0">
      <div>
        <p class="sm">Name:</p>
        <p class="text">{{ params?.name }}</p>
      </div>
      <div>
        <p class="sm">Email:</p>
        <p class="text">{{ params?.email }}</p>
      </div>
      <div>
        <p class="sm">Total Brands:</p>
        <p class="text">{{ params?.vendor?.totalBrands }}</p>
        <!-- <div class="ml-1">
          <ng-template #brandDetail>
            <div class='tooltip-custom'>
              <p *ngFor="let brand of brands">
                {{ brand.name }}
              </p>
            </div>
          </ng-template>
          <i class="info-ic mr-1" [ngbTooltip]="brandDetail"
            placement="top-left">
            <img src="assets/images/info.png" width="18" height="18" alt="">
          </i>
        </div> -->
      </div>
      <div>
        <p class="sm">Total Outlets:</p>
        <p class="text">{{ params?.vendor?.totalOutlets }}</p>
        <!-- <div class="ml-1">
          <ng-template #outletDetail>
            <div class='tooltip-custom'>
              <p *ngFor="let outlet of outlets">
                {{ outlet.name }}
              </p>
            </div>
          </ng-template>
          <i class="info-ic mr-1" [ngbTooltip]="outletDetail"
            placement="top-left">
            <img src="assets/images/info.png" width="18" height="18" alt="">
          </i>
        </div> -->
      </div>
      <div>
        <p class="sm">Plan:</p>
        <p class="text">
          {{ params?.plans }}
          <!-- <a href="javascript: //">Update plan</a> -->
        </p>
      </div>
      <div>
        <p class="sm">Plan Price:</p>
        <p class="text">{{ params?.plan?.price }}</p>
      </div>
      <div>
        <p class="sm">Contact Number:</p>
        <p class="text">{{ params?.mobile }}</p>
      </div>
      <div>
        <p class="sm">Activation Date:</p>
        <p class="text">{{ params?.startDate | date }}</p>
      </div>
      <div>
        <p class="sm">Expiry Date:</p>
        <p class="text">{{ params?.expiryDate | date }}</p>
      </div>
      <div>
        <p class="sm">Extended Expiry Date:</p>
        <p class="text">{{ params?.subscription?.baseExpiryDate | date }}</p>
      </div>
      <div>
        <p class="sm">Status:</p>
        <div class="togglesw">
          <div class="toggleSwitch">
            <input
              type="checkbox"
              (change)="changeStatus($event.target.checked)"
              [checked]="params?.isActive"
            />
            <span></span>
          </div>
          <label class="mb-0">{{
            params?.isActive ? "Active" : "Inactive"
          }}</label>
        </div>
      </div>
    </form>

    <!-- *ngIf="remainigPlanTime <= 10" -->
    <div>
      <form class="modalDetails-in pt-0" [formGroup]="formGroup">
        <div class="align-items-start form-in">
          <p class="sm">Plan Extend Date</p>
          <div class="extend-wrpr">
            <input
              type="date"
              formControlName="expiryDate"
              min="0"
              (keypress)="onlyNumbers($event)"
              (focusout)="onFocusOut($event)"
            />
            <div
              *ngIf="
                formGroup.get('expiryDate')?.invalid &&
                formGroup.get('expiryDate')?.touched
              "
            >
              <span
                class="error"
                *ngIf="formGroup.get('expiryDate')?.hasError('required')"
                >Date is required</span
              >
              <span
                class="error"
                *ngIf="formGroup.get('expiryDate')?.hasError('incorrect')"
                >Please enter date greater than expiry date</span
              >
            </div>
          </div>
        </div>

        <div class="text-right">
          <button
            type="button"
            class="btn next"
            [ngClass]="{ loader: loading.form }"
            (click)="submit()"
          >
            Extend Plan
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
