import { Inject, Injector, Injectable } from '@angular/core';
import { DpModal } from '../_modal/modal';
import { DpModalParams } from '../_modal/modal.params';
import { DpModalOptions } from '../_modal/modal-config';
import { DpActiveModal, DpModalRef } from '../_modal/modal.ref';

class curentModals {
  id?: string;
  modal: DpModalRef;
}

@Injectable({providedIn:'root'})
export class DpModalService {

  allModals: curentModals[] = [];

  constructor(
    private injector: Injector,
    // private activeModal: DpActiveModal,
    @Inject(DpModal) private modalService: DpModal) { }


  /**
   * Open Overlay Popup
   * @param modalId
   * @param entryComponent
   * @param popupOptions
   * @param popupParams
   */
  open(modalId, entryComponent, popupOptions: DpModalOptions = {}, popupParams: DpModalParams = {}) {
    const modalComponentRef = this.modalService.open(entryComponent, {
      ...{ windowId: modalId, ...popupOptions},
      injector: Injector.create([{
        provide: DpModalParams, useValue: popupParams
      }], this.injector)
    });
    this.afterClose(modalComponentRef, modalId);
    this.allModals.push({id: modalId, modal: modalComponentRef});
    return modalComponentRef;
  }

  /**
   * After Close Popup
   * @param modalRef
   * @param modalId
   */
  afterClose(modalRef, modalId) {
    let foundModal = this.allModals.find(_singleModal => _singleModal.id == modalId);
    // on close modal component
    modalRef.result.then(
      (data) => {
        this.removeModal(foundModal);
      },
      (reason) => {
        this.removeModal(foundModal);
      }
    );
  }

  /**
   * Remove Modal
   * @param modalId
   */
  removeModal(_modal) {
    this.allModals.splice(this.allModals.indexOf(_modal), 1);
  }

  // popup close
  close(modalId, msg = 'Cross click') {
    const foundModal = this.allModals.find(_singleModal => _singleModal.id == modalId);
    if(foundModal) {
      foundModal.modal.close(msg);
      // remove modal from current modals
      this.removeModal(foundModal);
    }
    else {
      // this.activeModal.dismiss(msg);
    }
  }

  // close all modals
  closeAll() {
    this.allModals.map(_modalRef => {
      _modalRef.modal.close('destroy');
    });
  }
}
