import {STEP_DATA_CONSTANTS, StepDataActionTypes} from '../actions';


const initialState = {step: 1};

export function StepDataReducer(state = initialState, action: StepDataActionTypes) {
  switch (action.type) {
    case STEP_DATA_CONSTANTS.SAVE_STEP_DATA:
      return {
        ...state,
        ...action.payload
      };

    case STEP_DATA_CONSTANTS.RESET_STEP_DETAIL:
      return initialState;

    default:
      return state;
  }
}
