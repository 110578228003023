import {APPLIEDFILTERS_CONSTANTS, AppliedFiltersActionTypes} from '../../actions/applied-filters.action';

const initialState = {updated: ''};

export function TableListingReducer(state = initialState, action: AppliedFiltersActionTypes) {
  switch (action.type) {
    case APPLIEDFILTERS_CONSTANTS.SAVE_TABLE_LISTING:
      return {
        ...state,
        ...action.payload
      };

    case APPLIEDFILTERS_CONSTANTS.RESET_DATA:
    case APPLIEDFILTERS_CONSTANTS.RESET_TABLE_LISTING:
      return initialState;

    default:
      return state;
  }
}
