import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { API_URL, HttpRequestService } from 'src/app/core';
import { SearchService } from 'src/app/shared/search.service';

@Component({
  selector: 'app-mark-status-ready-popup',
  templateUrl: './mark-status-ready-popup.component.html',
  styleUrls: ['./mark-status-ready-popup.component.scss']
})
export class MarkStatusReadyPopupComponent implements OnInit {

  constructor(
    private httpRequestService: HttpRequestService,
    private toastrService: ToastrService,
    private popupService: BsModalService,
    private searchService: SearchService
  ) { }

  params;

  ngOnInit(): void {
  }

  delete(){
    this.searchService.onMarkStatus(true);
    this.popupService.hide();
    const url = '';
    const param = this.params.id;
    this.httpRequestService.deleteData(url,param).subscribe(
      (resp)=>{
        this.searchService.onMarkStatus(true);
        this.toastrService.success('Status Marked as Ready', 'Success');
        this.popupService.hide();
      },(error)=>{

      }
    )
  }

  cancel(){
    this.popupService.hide();
  }

}
